<template>
    <div class="lk-page_orders">
        <p class="lk-page_orders-header">Заказы</p>
        <div class="lk-page_orders-filters">
            <div class="lk-page_orders-filters_ones" style="margin-right: 10px; width: 68%;">
                <input 
                    type="text" 
                    class="filters_search_input" 
                    placeholder="Поиск по номеру заказа" 
                    v-model="searchOrderForm.query" 
                    @change="getAllOrders" 
                    @keydown.enter="getAllOrders" 
                    @paste="pasteQuery"
                >
                <svg class="svg_search" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.96907 9.96387L14 14M11.3333 6.66667C11.3333 9.244 9.244 11.3333 6.66667 11.3333C4.08934 11.3333 2 9.244 2 6.66667C2 4.08934 4.08934 2 6.66667 2C9.244 2 11.3333 4.08934 11.3333 6.66667Z" stroke="#718093" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>
            <div class="lk-page_orders-filters_ones" style="margin-right: 10px; width: 15%;">
                <input type="text" :placeholder="rangeFrom == 0 && rangeTo == maxSellOrder ? 'Сумма' : ''" readonly @click="showRangeBlock">

                <div 
                    v-if="rangeFrom != 0 || rangeTo != maxSellOrder" 
                    class="range_setted_values_block"
                    @click="showRangeBlock"    
                >
                    <label for="input_range_from">От</label>
                    <p>{{ rangeFrom }}</p>
                    <label for="input_range_to">До</label>
                    <p>{{ rangeTo }}</p>
                </div>
                
                <div 
                    v-if="isShowRangeBlock"
                    class="range_block"
                    @mouseover="showRangeBlock"
                    @mouseleave="closeRangeBlock"
                >
                    <div class="vue-slider-block" :style="!isShowRangeLine ? 'height: 100px;' : ''">
                        <vue-slider 
                            v-if="isShowRangeLine"
                            v-model="valueRange" 
                            :max="maxSellOrder" 
                            :tooltip="'always'"
                        ></vue-slider>
                    </div>
                    <div class="range_inputs">
                        <div class="range_inputs_once">
                            <input type="text" id="input_range_from" v-model="rangeFrom" @keyup="changeRangeTyping('from')">
                            <label for="input_range_from">От</label>
                        </div>
                        <div class="range_inputs_once">
                            <input type="text" id="input_range_to" v-model="rangeTo" @keyup="changeRangeTyping('to')">
                            <label for="input_range_to">До</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="lk-page_orders-filters_ones" style="width: 15%;">
                <date-picker v-model="date" range placeholder="дд.мм.гг - дд.мм.гг" @change="setDates"></date-picker>
            </div>
            <div class="lk-page_orders-filters_ones mr-2" style="width: 200px;">
                <div class="filter_button" @click="isShowStatusList = !isShowStatusList">
                    <div class="status_filter_block">
                        <svg class="svg_filter" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M3 4.5C3 4.10218 3.15804 3.72064 3.43934 3.43934C3.72064 3.15804 4.10218 3 4.5 3H19.5C19.8978 3 20.2794 3.15804 20.5607 3.43934C20.842 3.72064 21 4.10218 21 4.5V6.586C20.9999 7.11639 20.7891 7.62501 20.414 8L15 13.414V20.838C15 21.0255 14.9521 21.2099 14.8608 21.3737C14.7695 21.5375 14.6379 21.6753 14.4783 21.7739C14.3188 21.8724 14.1368 21.9286 13.9494 21.9371C13.7621 21.9455 13.5757 21.9059 13.408 21.822L9.691 19.964C9.48337 19.8602 9.30875 19.7006 9.1867 19.5031C9.06466 19.3057 9.00001 19.0781 9 18.846V13.414L3.586 8C3.2109 7.62501 3.00011 7.11639 3 6.586V4.5ZM5 5V6.586L10.56 12.146C10.6994 12.2853 10.8101 12.4507 10.8856 12.6327C10.9611 12.8148 11 13.0099 11 13.207V18.382L13 19.382V13.207C13 12.809 13.158 12.427 13.44 12.147L19 6.585V5H5Z" fill="#050505"/>
                        </svg>
    
                        <p>Статус</p>
                    </div>
                    <svg class="svg_arrow" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.52729 5.52876C3.78764 5.26841 4.20975 5.26841 4.4701 5.52876L7.9987 9.05735L11.5273 5.52876C11.7876 5.26841 12.2098 5.26841 12.4701 5.52876C12.7305 5.78911 12.7305 6.21122 12.4701 6.47157L8.4701 10.4716C8.20975 10.7319 7.78764 10.7319 7.52729 10.4716L3.52729 6.47157C3.26694 6.21122 3.26694 5.78911 3.52729 5.52876Z" fill="#050505"/>
                    </svg>
                </div>
                
                <div 
                    v-if="isShowStatusList"
                    class="filter_list"
                    @mouseleave="isShowStatusList = false"
                >
                    <div 
                        class="filter_list_row"
                        v-for="status in orderStatuses"
                        :key="status.id"
                        @click="addStatusToForm(status.name)"
                    >
                        <svg v-if="!checkStatusFormList(status.name).value" class="svg_checkbox" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="1" y="1" width="14" height="14" rx="1" stroke="#718093" stroke-width="2"/>
                        </svg>

                        <svg v-if="checkStatusFormList(status.name).value" class="svg_checkbox" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="16" height="16" rx="2" fill="#ED1C24"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.8536 4.14645C14.0488 4.34171 14.0488 4.65829 13.8536 4.85355L6.85355 11.8536C6.65829 12.0488 6.34171 12.0488 6.14645 11.8536L2.64645 8.35355C2.45118 8.15829 2.45118 7.84171 2.64645 7.64645C2.84171 7.45118 3.15829 7.45118 3.35355 7.64645L6.5 10.7929L13.1464 4.14645C13.3417 3.95118 13.6583 3.95118 13.8536 4.14645Z" fill="white"/>
                        </svg>
                        <label :for="status.name">{{ status.name }}</label>
                    </div>
                </div>
            </div>

            <div class="lk-page_orders-filters_ones mr-2" style="width: 200px;">
                <div class="filter_button" @click="isShowCompanyList = !isShowCompanyList">
                    <p>Организации</p>
                    <svg class="svg_arrow" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.52729 5.52876C3.78764 5.26841 4.20975 5.26841 4.4701 5.52876L7.9987 9.05735L11.5273 5.52876C11.7876 5.26841 12.2098 5.26841 12.4701 5.52876C12.7305 5.78911 12.7305 6.21122 12.4701 6.47157L8.4701 10.4716C8.20975 10.7319 7.78764 10.7319 7.52729 10.4716L3.52729 6.47157C3.26694 6.21122 3.26694 5.78911 3.52729 5.52876Z" fill="#050505"/>
                    </svg>
                </div>
                
                <div 
                    v-if="isShowCompanyList"
                    class="filter_list" 
                    style="width: 170%;"
                    @mouseleave="isShowCompanyList = false"
                >
                    <div 
                        class="filter_list_row"
                        v-for="company in companies"
                        :key="company.id"
                        @click="addCompanyToForm(company.id_1c)"
                    >
                        <svg v-if="!checkCompanyFormList(company.id_1c).value" class="svg_checkbox" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="1" y="1" width="14" height="14" rx="1" stroke="#718093" stroke-width="2"/>
                        </svg>

                        <svg v-if="checkCompanyFormList(company.id_1c).value" class="svg_checkbox" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="16" height="16" rx="2" fill="#ED1C24"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.8536 4.14645C14.0488 4.34171 14.0488 4.65829 13.8536 4.85355L6.85355 11.8536C6.65829 12.0488 6.34171 12.0488 6.14645 11.8536L2.64645 8.35355C2.45118 8.15829 2.45118 7.84171 2.64645 7.64645C2.84171 7.45118 3.15829 7.45118 3.35355 7.64645L6.5 10.7929L13.1464 4.14645C13.3417 3.95118 13.6583 3.95118 13.8536 4.14645Z" fill="white"/>
                        </svg>
                        <label :for="company.name">{{ company.name }}</label>
                    </div>
                </div>
            </div>
            <div 
                v-for="statusFL in statusesFormList"
                :key="statusFL"
                class="lk-page_orders-filters_ones col-2 mr-2"
            >
                <div class="filter_button" style="background-color: #353B48; color: #fff;">
                    <p>{{ statusFL }}</p>
                    <svg @click="addStatusToForm(statusFL, 'btn')" width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18.2983 5.71022C18.2058 5.61752 18.0959 5.54397 17.9749 5.49379C17.8539 5.44361 17.7242 5.41778 17.5933 5.41778C17.4623 5.41778 17.3326 5.44361 17.2116 5.49379C17.0907 5.54397 16.9808 5.61752 16.8883 5.71022L11.9983 10.5902L7.10827 5.70022C7.01569 5.60764 6.90578 5.5342 6.78481 5.4841C6.66385 5.43399 6.5342 5.4082 6.40327 5.4082C6.27234 5.4082 6.14269 5.43399 6.02173 5.4841C5.90076 5.5342 5.79085 5.60764 5.69827 5.70022C5.60569 5.79281 5.53225 5.90272 5.48214 6.02368C5.43204 6.14464 5.40625 6.27429 5.40625 6.40522C5.40625 6.53615 5.43204 6.6658 5.48214 6.78677C5.53225 6.90773 5.60569 7.01764 5.69827 7.11022L10.5883 12.0002L5.69827 16.8902C5.60569 16.9828 5.53225 17.0927 5.48214 17.2137C5.43204 17.3346 5.40625 17.4643 5.40625 17.5952C5.40625 17.7262 5.43204 17.8558 5.48214 17.9768C5.53225 18.0977 5.60569 18.2076 5.69827 18.3002C5.79085 18.3928 5.90076 18.4662 6.02173 18.5163C6.14269 18.5665 6.27234 18.5922 6.40327 18.5922C6.5342 18.5922 6.66385 18.5665 6.78481 18.5163C6.90578 18.4662 7.01569 18.3928 7.10827 18.3002L11.9983 13.4102L16.8883 18.3002C16.9809 18.3928 17.0908 18.4662 17.2117 18.5163C17.3327 18.5665 17.4623 18.5922 17.5933 18.5922C17.7242 18.5922 17.8538 18.5665 17.9748 18.5163C18.0958 18.4662 18.2057 18.3928 18.2983 18.3002C18.3909 18.2076 18.4643 18.0977 18.5144 17.9768C18.5645 17.8558 18.5903 17.7262 18.5903 17.5952C18.5903 17.4643 18.5645 17.3346 18.5144 17.2137C18.4643 17.0927 18.3909 16.9828 18.2983 16.8902L13.4083 12.0002L18.2983 7.11022C18.6783 6.73022 18.6783 6.09022 18.2983 5.71022Z" fill="white"/>
                    </svg>
                </div>
            </div>
            <div class="lk-page_orders-filters_ones col-2">
                <div @click="clearStatus" class="filter_button" style="justify-content: center; background-color: #fff;">
                    <p>Сбросить все</p>
                </div>
            </div>
        </div>

        <div v-if="orders.length == 0" class="empty_orders_block">
            <img src="@/assets/img/empty_orders.png" alt="">
            <p class="empty_orders_header">У вас пока нет заказов!</p>
            <p class="empty_orders_subheader">Здесь будет храниться история и статусы ваших заказов</p>
            <button class="button button--primary" @click="push('catalog')">Перейти в каталог</button>
        </div>

        <div v-if="orders.length != 0" class="lk-page_orders-list" id="orders_list" @wheel="checkPositionWindow">
            <div 
                class="lk-page_orders-ones"
                v-for="order in orders"
                :key="order.id"
            >
                <div class="lk-page_orders-ones_left-part">
                    <div>
                        <div 
                            class="order-status-button"
                            :class="[
                                order.order_status == 'Отгружен' || order.order_status == 'Оформлен' ? 'order-status-button_lightgreen' : '',
                                order.order_status == 'Готов к отгрузке' ? 'order-status-button_lightblue' : '',
                                order.order_status == 'Подтвердить отгрузку' ? 'order-status-button_yellow' : '',
                                order.order_status == 'В производстве' ? 'order-status-button_purple' : '',
                                order.order_status == 'Оплачено' || order.order_status == 'Выполнен' ? 'order-status-button_green' : '',
                                order.order_status == 'Не оплачено' || order.order_status == 'Не подтвержден' || order.order_status == 'Отклонен' ? 'order-status-button_red' : '',
                                order.order_status == 'В архиве' ? 'order-status-button_black' : '',
                                order.order_status == 'Подтвержден' ? 'order-status-button_orange' : '',
                                order.order_status == 'В обработке' ? 'order-status-button_blue' : '',
                                order.order_status == 'Черновик' || order.order_status == 'Новый' ? 'order-status-button_grey' : '',
                            ]"
                        >
                            {{ order.order_status }}
                        </div>
                    </div>
                    <div class="order-number">
                        <p>Заказ №{{ order.order_id_1c }} <br> от {{ foramtDateFromISO(order.created_at) }}</p>
                    </div>
                    <div class="company-name">
                        <p>{{ order.organization_name }}</p>
                    </div>
                </div>
                <div class="lk-page_orders-ones_mid-part">
                    <div class="mid_part_row">
                        <p class="mid_part_row-value">{{ checkSumNds(order.sum_sell, order.sum_pay, order.sum_balance, order) }} руб.</p>
                        <p class="mid_part_row-name">Сумма с НДС</p>
                    </div>
                    <div class="mid_part_row">
                        <p class="mid_part_row-value">{{ order.sum_pay ? getSumWithSpaces(order.sum_pay) : 0 }} руб.</p>
                        <p class="mid_part_row-name">Сумма оплачено</p>
                    </div>
                    <div v-if="((order.order_status == 'Черновик' || order.order_status == 'Новый') && checkSumNds(order.sum_sell, order.sum_pay, order.sum_balance, order) != 0) || getSumWithSpaces(order.sum_balance) != 0" class="mid_part_row">
                        <p class="mid_part_row-value">{{ (order.order_status == 'Черновик' || order.order_status == 'Новый') ? getOrderSumBalance(order) : getSumWithSpaces(order.sum_balance) }} руб.</p>
                        <p class="mid_part_row-name">Не оплачено</p>
                    </div>
                </div>
                <div class="lk-page_orders-ones_right-part">
                    <div class="right-part_subheader">
                        <p>Ответственный менеджер</p>
                    </div>
                    <div class="right-part_manager">
                        <p>{{ order.manager }}</p>
                    </div>
                </div>
                <div class="order_button_block">
                    <div class="order_button_row">
                        <div @click="pushToSingleOrder(order.id)" class="order-button">
                            Детали заказа
    
                            <svg width="21" height="16" viewBox="0 0 21 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M20.7071 8.70711C21.0976 8.31658 21.0976 7.68342 20.7071 7.29289L14.3431 0.928932C13.9526 0.538408 13.3195 0.538408 12.9289 0.928932C12.5384 1.31946 12.5384 1.95262 12.9289 2.34315L18.5858 8L12.9289 13.6569C12.5384 14.0474 12.5384 14.6805 12.9289 15.0711C13.3195 15.4616 13.9526 15.4616 14.3431 15.0711L20.7071 8.70711ZM0 9H20V7H0V9Z" fill="white"/>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Vuex from 'vuex'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/ru';

import Functions from '@/utils/functions'
import Acl from '@/utils/acl'

export default {
    mixins: [Functions, Acl],
    components: {
        VueSlider,
        DatePicker,
    },
    data() {
        return {
            isShowStatusList: false,
            isShowCompanyList: false,
            valueRange: [0, 500000],
            rangeFrom: 0,
            rangeTo: 500000,
            isShowRangeBlock: false,
            date: null,
            isShowRangeLine: true,
            idTimerShowRangeBlock: 0,
            isLoading: false,
        }
    },
    computed: {
        ...Vuex.mapGetters({
            searchForm: 'basket/searchForm',
            orders: 'basket/orders',
            orderStatuses: 'dictionary/orderStatuses',
            companies: 'companies/companies',
            maxSellOrder: 'basket/maxSellOrder',
            formCompanies: 'companies/form',
            user: 'auth/user',
        }),
        searchOrderForm() {
			return Object.assign({}, this.searchForm)
		},
        statusesFormList() {
            return this.searchOrderForm.status
        },
        searchCompaniesForm() {
			return Object.assign({}, this.formCompanies)
		},
    },
    watch: {
        valueRange(val) {
            this.rangeFrom = val[0]
            this.rangeTo = val[1]
        },
        isShowStatusList(val) {
            if (val == false) {
                this.getAllOrders()
            }
        },
        isShowCompanyList(val) {
            if (val == false) {
                this.getAllOrders()
            }
        },
        isShowRangeBlock(val) {
            if (val == false) {
                this.getAllOrders()
            }
        },
    },
    destroyed() {
        this.clearStatus()
    },
    mounted() {
        this.getCompaniesList()
        this.setMaxRange()
        this.setEventListener()
    },
    methods: {
        ...Vuex.mapMutations({
            changeSearchForm: 'basket/changeSearchForm',
			setCanSearchOrder: 'basket/setCanSearchOrder',
            pushStatusForm: 'basket/pushStatusForm',
            spliceStatusForm: 'basket/spliceStatusForm',
            pushCompanyForm: 'basket/pushCompanyForm',
            spliceCompanyForm: 'basket/spliceCompanyForm',
            clearStatusForm: 'basket/clearStatusForm',
            updateCompaniesForm: 'companies/updateForm',
        }),
        ...Vuex.mapActions({
            searchOrder: 'basket/searchOrder',
            getCompanies: 'companies/getCompanies',
            searchNextPageOrder: 'basket/searchNextPageOrder',
            getMaxSell: 'basket/getMaxSell',
        }),
        getSumWithSpaces(sum) {
            if (sum == 0 || sum == null) {
                return 0
            }
            let parts = sum.toString().split(".")
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ")
            return parts.join(".")
        },
        getOrderSumBalance(order) {
            let balance = 0
            let fixedPrice = 0
            if (order.positions != null && order.positions.length != 0) {
                order.positions.map((element) => {
                    balance += element.sell
                })
                
                if (balance) {
                    fixedPrice = ( (balance.toString().includes('.')) ? (balance.toString().split('.').pop().length) : (0) )
                }

                balance = (fixedPrice >= 2 ? balance.toFixed(2) : balance)
            }
            return this.getSumWithSpaces(balance)
        },
        checkSumNds(sell, pay, balance, order) {
            let value = 0
            if (sell == null || sell == 0 || sell == '') {
                if (balance == null || balance == 0 || balance == '') {
                    balance = this.getOrderSumBalance(order)
                }
                if (pay == null || pay == 0 || pay == '') {
                    value = balance
                }else{
                    value = pay + balance
                }
            }else{
                value = sell
            }
            return this.getSumWithSpaces(value)
        },
        getCompaniesList() {
            if (!this.can('admin')) {
                let user_companies = this.user.user_link_company
                let array = []
                user_companies.map((element) => {
                    if(element.is_admin == true) {
                        array.push(element.company_id)
                    }
                })

                this.searchCompaniesForm.ids = array
                this.updateCompaniesForm(this.searchCompaniesForm)
            }else{
                this.searchCompaniesForm.ids = []
                this.updateCompaniesForm(this.searchCompaniesForm)
            }
            this.getCompanies()
        },
        setMaxRange() {
            this.getMaxSell()
                .then(() => {
                    this.valueRange[1] = this.maxSellOrder
                    this.rangeTo = this.maxSellOrder
                    this.isShowRangeLine = false
                    setTimeout(() => {
                        this.isShowRangeLine = true  
                    }, 1)

                    this.getAllOrders()
                })
        },
        setEventListener() {
            let scrollBlock = document.getElementById('orders_list')
            if (scrollBlock) {
                scrollBlock.addEventListener('scroll', this.checkPositionWindow)
            }
        },
        checkPositionWindow() {
            let positionScroll = document.getElementById('orders_list')
			if (Math.floor(positionScroll.scrollHeight - positionScroll.scrollTop) === positionScroll.clientHeight) {
				if (this.isLoading == true) {
					return
				}
                this.isLoading = true
				this.searchOrderForm.page += 1
				this.changeSearchForm(this.searchOrderForm)
				this.searchNextPageOrder()
                    .then(() => {
                        this.isLoading = false
                    })
			}
		},
        changeRangeTyping(type) {
            if (type == 'from') {
                this.valueRange[0] = this.rangeFrom
            }else if (type == 'to') {
                this.valueRange[1] = this.rangeTo
            }

            this.isShowRangeLine = false
            setTimeout(() => {
                this.isShowRangeLine = true  
            }, 1)
        },
        pasteQuery() {
            setTimeout(() => {
                this.getAllOrders()
            }, 100)
        },
        getAllOrders(){
            if (this.isLoading == true) {
                return
            }
            this.isLoading = true
            this.setCanSearchOrder(true)
            this.searchOrderForm.page = 1
            this.searchOrderForm.limit = 10
            if (Number(this.rangeFrom) == 0 && Number(this.rangeTo) == this.maxSellOrder) {
                this.searchOrderForm.sumFrom = null
                this.searchOrderForm.sumTo = null
            }else{
                this.searchOrderForm.sumFrom = Number(this.rangeFrom)
                this.searchOrderForm.sumTo = Number(this.rangeTo)
            }
            this.changeSearchForm(this.searchOrderForm)
            this.searchOrder()
                .then(() => {
                    this.isLoading = false
                })
        },
        checkStatusFormList(string) {
            let isIsset = false
            let array = this.searchOrderForm.status
            let index = null
            array.map((element, idx) => {
                if (element == string) {
                    isIsset = true
                    index = idx
                }
            })
            return {value: isIsset, index: index}
        },
        checkCompanyFormList(id_1c) {
            let isIsset = false
            let array = this.searchOrderForm.company_id1cs
            let index = null
            if (Array.isArray(array)) {
                array.map((element, idx) => {
                    if (element == id_1c) {
                        isIsset = true
                        index = idx
                    }
                })
            }
            return {value: isIsset, index: index}
        },
        addStatusToForm(string, type) {
            let checkObject = this.checkStatusFormList(string)
            if (!checkObject.value) {
                this.pushStatusForm(string)
            }else{
                this.spliceStatusForm(checkObject.index)
            }
            this.changeSearchForm(this.searchOrderForm)
            if (type && type == 'btn') {
                this.getAllOrders()
            }
            
        },
        addCompanyToForm(id_1c) {
            let checkObject = this.checkCompanyFormList(id_1c)
            if (!checkObject.value) {
                this.pushCompanyForm(id_1c)
            }else{
                this.spliceCompanyForm(checkObject.index)
            }
            this.changeSearchForm(this.searchOrderForm)
            // this.getAllOrders()
        },
        setDates() {
            let five_hours = 18000000
            if (this.date[0]) {
                let ms = this.date[0].getTime()
                this.searchForm.date_create = new Date(ms + five_hours)
            }else{
                this.searchForm.date_create = null
            }
            if (this.date[1]) {
                let ms = this.date[1].getTime()
                this.searchForm.date_before = new Date(ms + five_hours)
            }else{
                this.searchForm.date_before = null
            }
            this.changeSearchForm(this.searchOrderForm)
            this.getAllOrders()
        },
        clearStatus() {
            this.searchOrderForm.page = 1
            this.searchOrderForm.limit = 10
            this.rangeFrom = 0
            this.rangeTo = this.maxSellOrder
            this.searchOrderForm.date_before = null
            this.searchOrderForm.date_create = null
            this.date = null
            this.searchOrderForm.query = null
            this.searchOrderForm.status = []
            this.searchOrderForm.company_id1cs = []
            this.changeSearchForm(this.searchOrderForm)
            this.getAllOrders()

            this.valueRange[0] = this.rangeFrom
            this.valueRange[1] = this.rangeTo
            this.isShowRangeLine = false
            setTimeout(() => {
                this.isShowRangeLine = true  
            }, 100)
        },
        showRangeBlock() {
            clearTimeout(this.idTimerShowRangeBlock)
            this.isShowRangeBlock = true
        },
        closeRangeBlock() {
            this.idTimerShowRangeBlock = setTimeout(() => {
                this.isShowRangeBlock = false
            }, 500)
        },
        pushToSingleOrder(id) {
            this.push('lk/single-order/' + id)
        }
    }
}
</script>
<style lang="scss">
    .mx-calendar-panel-date{
        .mx-btn-icon-double-left, .mx-btn-icon-double-right{
            display: none;
        }
        
    }

    .mx-calendar{
        padding: 24px 36px;
        width: 400px;
        height: 410px;

        .mx-calendar-header{

            .mx-btn-icon-left, .mx-btn-icon-right{
                background-color: #718093;
                color: #fff;
                border-radius: 16px;
                height: 32px;
                width: 32px;
                text-align: center;

                i{
                    &:before{
                        border-width: 3px 0 0 3px;
                    }
                }
            }

            .mx-btn-icon-left{
                float: unset;
            }

            .mx-calendar-header-label{
                float: left;

                button{
                    color: black;
                    font-weight: 700;
                    font-size: 22px;
                    line-height: 33px;
                    font-family: 'Montserrat', serif !important;
                }
            }
        }

        .mx-calendar-content{
            .mx-table{
                th{
                    color: #696969;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 21px;
                    font-family: 'Montserrat', serif !important;
                }

                td{
                    color: black;
                    font-weight: 400;
                    font-size: 22px;
                    line-height: 33px;
                    font-family: 'Montserrat', serif !important;
                    width: 45px;
                    height: 50px;


                    &:hover{
                        background-color: #F5F6FA;
                        border-radius: 100px;
                    }
                }

                .active{
                    background-color: #DFE3F0;
                    border-radius: 100px;
                }

                .in-range{
                    background-color: #F5F6FA;
                    border-radius: 100px;
                }
                .hover-in-range{
                    background-color: #F5F6FA;
                    border-radius: 100px;
                }
            }
        }
    }
</style>