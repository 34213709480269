<template>
    <div class="nlk-horizontal-scroll">
        <div class="accordion nlk-accordion">
            <div 
                class="nlk-accordion-item" 
                :class="{'-active' : isOpenButtonReports}"
            >
                <header 
                    class="nlk-order-header pl-0" 
                    :class="'border-' + getColor()"
                >
                    <div class="nlk-order-header__top pl-0 pr-0">
                        <div class="nlk-order-header__item first_column mar-0">
                            Заказ № {{ order.order_id_1c }}
                            <small>от {{ getDate() }}</small>
                        </div>
                        <div v-if="regionCurrency[regionCode]" class="nlk-order-header__item second_column mar-0">
                            {{ checkSumNds(order.sum_sell, order.sum_pay, order.sum_balance) }}
                            {{ regionCurrency[regionCode].currency }}.
                        </div>
                        <div v-if="regionCurrency[regionCode]" class="nlk-order-header__item third_column mar-0">
                            {{ order.sum_pay ? getSumWithSpaces(order.sum_pay) : 0 }}
                            {{ regionCurrency[regionCode].currency }}.
                        </div>
                        <div v-if="regionCurrency[regionCode]" class="nlk-order-header__item fourth_column mar-0">
                            {{ order.sum_balance ? getSumWithSpaces(order.sum_balance) : getOrderSumBalance() }} 
                            {{ regionCurrency[regionCode].currency }}.
                        </div>
                        <div v-if="regionCurrency[regionCode]" class="nlk-order-header__item fiveth_column mar-0" :class="'color-' + getColor()">
                            {{ order.order_status }}
                        </div>
                        <div class="nlk-order-header__item sixth_column mar-0">
                            {{ order.manager }}
                        </div>
                    </div>
                    <div class="nlk-order-header__bottom">
                        <!-- {{ order.links_certificates }} -->
                        <div 
                            v-if="!isEmptyElement(order.links_certificates)"
                            class="nlk-order-header__item" 
                            @click="openDownloadWindow(order.links_certificates[0])"
                        >
                            <a>Скачать сертификаты</a>
                        </div>
                        <!-- {{ order.links_specifications }} -->
                        <div 
                            v-if="!isEmptyElement(order.links_specifications)"
                            class="nlk-order-header__item"
                            @click="openDownloadWindow(order.links_specifications[0])"
                        >
                            <a>Сформировать/Скачать спецификацию</a>
                        </div>
                        <!-- {{ order.links_payment[0] }} -->
                        <div 
                            v-if="!isEmptyElement(order.links_payment)"
                            class="nlk-order-header__item"
                            @click="openDownloadWindow(order.links_payment[0])"
                        >
                            <a>Сформировать/Скачать счет</a>
                        </div>
                        <!-- {{ order.links_shipping_documents[0] }} -->
                        <div 
                            v-if="!isEmptyElement(order.links_shipping_documents)"
                            class="nlk-order-header__item"
                            @click="openDownloadWindow(order.links_shipping_documents[0])"
                        >
                            <a>Отгрузочные документы</a>
                        </div>
                        <div 
                            class="nlk-order-header__item" 
                            v-if="order.positions"
                            @click="repeatOrder"
                        >
                            <a>Повторить заказ</a>
                        </div>
                        <div 
                            v-if="order.order_status == 'Черновик'"
                            class="nlk-order-header__item" 
                            @click="deleteOrder"
                        >
                            <a>Удалить заказ</a>
                        </div>
                    </div>
                    <button class="nlk-accordion-btn" @click="isOpenButtonReports = !isOpenButtonReports">
                        <img src="@/assets/img/select-basic.svg" alt="">
                    </button>
                </header>
            </div>
            <div
                v-if="isOpenButtonReports" 
                class="nlk-accordion-body nlk-accordion-body-custom" 
                :class="{'-active' : isOpenButtonReports}"
            > 
                <div class="nlk-status nlk-status-custom">
                    <div class="nlk-status__title">Статус</div>
                    <ul class="nlk-status__list">
                        <li 
                            :class="{'active' : activeFilter == 1}"
                            @click="activeFilter = 1"
                        >
                            <a>Заказ</a>
                        </li>
                        <li
                            :class="{'active' : activeFilter == 2}"
                            @click="activeFilter = 2"
                        >
                            <a>В производстве</a>
                        </li>
                        <li
                            :class="{'active' : activeFilter == 3}"
                            @click="activeFilter = 3"
                        >
                            <a>На складе</a>
                        </li>
                        <li
                            :class="{'active' : activeFilter == 4}"
                            @click="activeFilter = 4"
                        >
                            <a>Отгружены</a>
                        </li>
                        <li
                            :class="{'active' : activeFilter == 5}"
                            @click="activeFilter = 5"
                        >
                            <a>В работе</a>
                        </li>
                    </ul>
                </div>
                <div class="nlk-accordion-table">
                    <table-in-order v-if="activeFilter == 1" :inOrder="order.positions" />

                    <table-in-production v-if="activeFilter == 2 && inProduction" :inProduction="inProduction" />

                    <table-in-stock v-if="activeFilter == 3 && inStock" :inStock="inStock" />
                    
                    <table-in-shipped v-if="activeFilter == 4 && inShipped" :inShipped="inShipped" />

                    <table-in-work v-if="activeFilter == 5 && inWork" :inWork="inWork" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Vuex from 'vuex'
import Functions from '@/utils/functions'
import tableInProduction from './orderTables/inProduction.vue'
import tableInStock from './orderTables/inStock.vue'
import tableInWork from './orderTables/inWork.vue'
import tableInShipped from './orderTables/inShipped.vue'
import tableInOrder from './orderTables/inOrder.vue'

// import { saveAs } from 'file-saver';

export default {
    mixins: [Functions],
    components: {
        tableInProduction,
        tableInStock,
        tableInWork,
        tableInShipped,
        tableInOrder,
    },
    props: {
        order: {
            type: Object,
            default: () => {}
        },
    },
    data() {
		return {
			isOpenButtonReports: false,
            statusName: null,
            activeFilter: 1,
            inProduction: [],
            inStock: [],
            inWork: [],
            inShipped: [],
            isShowModalError: false,
		}
	},
    computed: {
        ...Vuex.mapGetters({
            regionCode: 'auth/regionCode',
            regionCurrency: 'dictionary/regionCurrency',
            yandexCloud: 'app/yandexCloud',
            currentOrder: 'basket/order',
            userCompanyId: 'auth/userCompanyId',
        }),
        filteredPositions() {
            let positions = this.order.positions.slice(0)
            let responseArray = []

            if (this.activeFilter == 2) {
                positions.map((element) => {
                    if (element.amount_in_stock == 0) {
                        responseArray.push(element)
                    }
                })
            }
            return responseArray
        }
    },
    watch: {
        activeFilter(val) {
            if (!this.order.order_id_1c) {
                return 
            }
            if (val == 2) {
                this.getStatusOrderInProduction(this.order.order_id_1c)
                    .then((response) => {
                        this.inProduction = response.data
                    })
            }
            if (val == 3) {
                this.getStatusOrderInStock(this.order.order_id_1c)
                    .then((response) => {
                        this.inStock = response.data
                    })
            }
            if (val == 4) {
                this.getStatusOrderInShipped(this.order.order_id_1c)
                    .then((response) => {
                        this.inShipped = response.data
                    })
            }
            if (val == 5) {
                this.getStatusOrderInWork(this.order.order_id_1c)
                    .then((response) => {
                        this.inWork = response.data
                    })
            }
        }
    },
    methods: {
        ...Vuex.mapActions({
            createOrder: 'basket/create',
            getStatusOrderInProduction: 'reports/getStatusOrderInProduction',
            getStatusOrderInStock: 'reports/getStatusOrderInStock',
            getStatusOrderInWork: 'reports/getStatusOrderInWork',
            getStatusOrderInShipped: 'reports/getStatusOrderInShipped',
            deleteStoreOrder: 'basket/deleteOrder',
            searchOrder: 'basket/searchOrder',
            copyOrder: 'basket/copyOrder',
        }),
        ...Vuex.mapMutations({
            setShowDeleteOrderModal: 'app/setShowDeleteOrderModal',
            setDeleteOrderId: 'app/setDeleteOrderId',
            setShowBasketNotEmpty: 'app/setShowBasketNotEmpty',
        }),
        getSumWithSpaces(sum) {
            let parts = sum.toString().split(".")
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ")
            return parts.join(".")
        },
        getOrderSumBalance() {
            let balance = 0
            let fixedPrice = 0
            if (this.order.sum_balance == null && this.order.positions != null && this.order.positions.length != 0) {
                this.order.positions.map((element) => {
                    balance += element.sell
                })
                
                if (balance) {
                    fixedPrice = ( (balance.toString().includes('.')) ? (balance.toString().split('.').pop().length) : (0) )
                }

                balance = (fixedPrice >= 2 ? balance.toFixed(2) : balance)
            }
            return this.getSumWithSpaces(balance)
        },
        getColor() {
            let color = null
            if (this.order.order_status == "Новый" || this.order.order_status == "Отгружен") {
                color = 'green'
            }else if (this.order.order_status == "Оформлен") {
                color = 'red'
            }else if (this.order.order_status == "Подтвержден") {
                color = 'orange'
            }else if (this.order.order_status == "В производстве") {
                color = 'purple'
            }else if (this.order.order_status == "Черновик" || this.order.order_status == "В архив") {
                color = ''
            }
            return color
        },
        getDate() {
            let date = null
            if (this.order.created_at) {
                let splited = this.order.created_at.split('-') 
                let splitedTime = splited[2].split('T')
                date = splitedTime[0] + '-' + splited[1] + '-' + splited[0]
            }
            return date
        },
        repeatOrder() {
            if (this.currentOrder.positions.length > 0) {
                this.setShowBasketNotEmpty(true)
                return
            }
            
            this.copyOrder({id: this.order.id, company_id: Number(this.userCompanyId)})
                .then((response) => {
                    localStorage.setItem('mayakOrderId', JSON.stringify(response.data.id))
                    this.push('basket')
                })
                .catch(() => {
                    this.$emit('openModal')
                })
        },
        getTextDeliveryType(type) {
            let val = null
            if (type == 10) {
                val = 'Самовывоз'
            }else if (type == 20) {
                val = 'Доставка'
            }
            return val
        },
        getParsedDate(date) {
            if (!date) {
                return null
            }
            let parsed = date.split('-')
            let parsedDay = parsed[2].split('T')
            return parsedDay[0] + '-' + parsed[1] + '-' + parsed[0]
        },
        deleteOrder() {
            this.setShowDeleteOrderModal(true)
            this.setDeleteOrderId(this.order.id)
        },
        openDownloadWindow(str) {
            // var FileSaver = require('file-saver')
            // FileSaver.saveAs('https://storage.yandexcloud.net/dev-mayakmetall/docs/' + this.order.order_id_1c + '/' + str)
            // FileSaver.saveAs('https://storage.yandexcloud.net/dev-mayakmetall/docs/С0000014474/certificates/ПаспортКачества2.pdf')
            window.open(this.yandexCloud + str)
        },
        checkSumNds(sell, pay, balance) {
            let value = 0
            if (sell == null || sell == 0 || sell == '') {
                if (balance == null || balance == 0 || balance == '') {
                    balance = this.getOrderSumBalance()
                }
                if (pay == null || pay == 0 || pay == '') {
                    value = balance
                }else{
                    value = pay + balance
                }
            }else{
                value = sell
            }
            return this.getSumWithSpaces(value)
        },
    }
}
</script>
<style scoped src="../../../css/nlk-style.css"></style>
<style scoped>
	.active{
		background-color: unset;
	}
    .nlk-order-header__top .first-item{
        min-width: 13.3rem;
        max-width: 13.3rem;
        margin-right: 0;
    }
    .td-wo_padding{
        padding: 0 !important;
    }
    .table-100{
        height: 100% !important;
    }
</style>
<style lang="scss">
    .nlk-accordion-body-custom{
        display: block !important;

        .nlk-status__list{
            display: flex;
            margin-bottom: 2rem;

            li{
                margin-right: 2rem;
            }
        }
    }
</style>