<template>
    <div class="modal-iframe delete_user_modal">
        <div class="modal modal--form modal--opened modal--visible" id="modal_callback">
            <div class="modal-container">
                <div class="state">
                    <div v-if="step < 3" class="header_block">
                        <p>Удаление аккаунта</p>
                        <svg @click="close" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18.3002 6.20998C18.2077 6.11728 18.0978 6.04373 17.9768 5.99355C17.8559 5.94337 17.7262 5.91754 17.5952 5.91754C17.4643 5.91754 17.3346 5.94337 17.2136 5.99355C17.0926 6.04373 16.9827 6.11728 16.8902 6.20998L12.0002 11.09L7.11022 6.19998C7.01764 6.1074 6.90773 6.03396 6.78677 5.98385C6.6658 5.93375 6.53615 5.90796 6.40522 5.90796C6.27429 5.90796 6.14464 5.93375 6.02368 5.98385C5.90272 6.03396 5.79281 6.1074 5.70022 6.19998C5.60764 6.29256 5.5342 6.40247 5.4841 6.52344C5.43399 6.6444 5.4082 6.77405 5.4082 6.90498C5.4082 7.03591 5.43399 7.16556 5.4841 7.28652C5.5342 7.40749 5.60764 7.5174 5.70022 7.60998L10.5902 12.5L5.70022 17.39C5.60764 17.4826 5.5342 17.5925 5.4841 17.7134C5.43399 17.8344 5.4082 17.964 5.4082 18.095C5.4082 18.2259 5.43399 18.3556 5.4841 18.4765C5.5342 18.5975 5.60764 18.7074 5.70022 18.8C5.79281 18.8926 5.90272 18.966 6.02368 19.0161C6.14464 19.0662 6.27429 19.092 6.40522 19.092C6.53615 19.092 6.6658 19.0662 6.78677 19.0161C6.90773 18.966 7.01764 18.8926 7.11022 18.8L12.0002 13.91L16.8902 18.8C16.9828 18.8926 17.0927 18.966 17.2137 19.0161C17.3346 19.0662 17.4643 19.092 17.5952 19.092C17.7262 19.092 17.8558 19.0662 17.9768 19.0161C18.0977 18.966 18.2076 18.8926 18.3002 18.8C18.3928 18.7074 18.4662 18.5975 18.5163 18.4765C18.5665 18.3556 18.5922 18.2259 18.5922 18.095C18.5922 17.964 18.5665 17.8344 18.5163 17.7134C18.4662 17.5925 18.3928 17.4826 18.3002 17.39L13.4102 12.5L18.3002 7.60998C18.6802 7.22998 18.6802 6.58998 18.3002 6.20998Z" fill="black"/>
                        </svg>
                    </div>
                    <div v-if="step < 3" class="content_block">
                        <p class="confirm_text" v-if="step == 1">Вы уверены, что хотите удалить аккаунт? Все данные будут утеряны</p>
                        <p class="confirm_text" v-if="step == 2">{{ 'СМС с кодом отправлен на номер ' + getFinallyPhoneWithSpaces() }}</p>
                        <input v-if="step == 2" type="text" v-model="number_sms" maxlength="6" placeholder="код">
                    </div>
                    <div v-if="step < 3" class="buttons_block">
                        <button v-if="step == 1" class="button button--default" @click="close">Отмена</button>
                        <button v-if="step == 1" class="button button--primary" @click="sendConfirmCode">Подвердить удаление по sms</button>
                        <button v-if="step == 2" class="button button--primary" style="width: 100%;" @click="confirmDeleteUser">Подвердить</button>
                    </div>
                    <div v-if="step == 2" class="resend_code_block">
                        <p v-if="smsTimer == 0" class="resend_link" @click="sendConfirmCode">Отправить код повторно</p>
                        <p v-if="smsTimer > 1">Отправить код повторно через {{ formatedTime(smsTimer) }}</p>
                    </div>
                    <div v-if="step == 3" class="header_block" style="justify-content: center; margin-bottom: 0;">
                        <p>Аккаунт удален!</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Vuex from 'vuex'
import Functions from '@/utils/functions'

export default {
    mixins: [Functions],
    data() {
        return {
            step: 1,
            number_sms: null,

            smsTimer: 0,
            timerId: null,
        }
    },
    computed: {
        ...Vuex.mapGetters({
            user: 'auth/user',
        }),
        editedUser() {
            return Object.assign({}, this.user)
        },
    },
    methods: {
        ...Vuex.mapMutations({

        }),
        ...Vuex.mapActions({
            verifyCode: 'auth/verify',
            sendsms: 'auth/sendsms',
            deleteUser: 'users/deleteUser',
        }),
        close() {
            this.$emit('closeDeleteModal')
        },
        getFinallyPhone() {
            var finallyPhone = ''
            var phone = this.editedUser.phone
            var splitedPhone = phone.split('')
            splitedPhone.map((element) => {
                if (!isNaN(element) && element != ' ') {
                    finallyPhone += element
                }
            })       
            if (finallyPhone.length == 10) {
                finallyPhone = '+7' + finallyPhone
            }else if (finallyPhone.length == 11) {
                finallyPhone = '+' + finallyPhone
            }
            return finallyPhone
        },
        getFinallyPhoneWithSpaces() {
            var finallyPhone = ''
            var phone = this.editedUser.phone
            var splitedPhone = phone.split('')
                
            if (splitedPhone.length == 10) {
                finallyPhone = '+7 (' +  splitedPhone[0] + splitedPhone[1] + splitedPhone[2] + ') ' + splitedPhone[3] + splitedPhone[4] + splitedPhone[5] + '-' + splitedPhone[6] + splitedPhone[7] + '-' + splitedPhone[8] + splitedPhone[9]
            }else if (splitedPhone.length == 11) {
                finallyPhone = '+' + finallyPhone
                finallyPhone = '+' +  splitedPhone[0] + ' (' + splitedPhone[1] + splitedPhone[2] + splitedPhone[3] + ') ' + splitedPhone[4] + splitedPhone[5] + splitedPhone[6] + '-'  + splitedPhone[7] + splitedPhone[8] + '-'  + splitedPhone[9] + splitedPhone[10]
            }
            return finallyPhone
        },
        sendConfirmCode() {
            this.sendsms({phone: this.getFinallyPhone()})
                .then(() => {
                    this.step = 2
                    this.startTimer(300)
                })
        },
        confirmDeleteUser() {
            this.deleteUser({
                id: this.editedUser.id,
                number_sms: Number(this.number_sms),
            })
                .then(() => {
                    this.step = 3
                    setTimeout(() => {
                        this.close()
                        this.push('login')
                    }, 2000)
                })
        },
        startTimer(time) {
            if (!time) {
                time = 300
            }else if (time && !Number(time)) {
                return
            }
            this.smsTimer = time
            if (time && time == 1) {
                this.smsTimer = 0
                return
            }
            this.timerId = setTimeout(function(){
                this.startTimer(time - 1)
                localStorage.setItem('sended_code_time', JSON.stringify(time)) 
            }.bind(this), 1000)
        },
        formatedTime(s) {
            let m = Math.trunc(s / 60) + ''
            s = (s % 60) + ''
            return m.padStart(2, 0) + ':' + s.padStart(2, 0)
        },
    }
}
</script>
<style lang="scss">
    .delete_user_modal{
        .modal-container{
            padding: 24px !important;
            max-width: 606px !important;
            border-radius: 5px;

            .header_block{
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 24px;

                p{
                    color: black;
                    font-weight: 700;
                    font-size: 22px;
                    line-height: 33px;
                    margin-bottom: 0;
                }

                svg{
                    &:hover{
                        cursor: pointer;
                    }
                }
            }

            .content_block{
                margin-bottom: 24px;

                .confirm_text{
                    color: black;
                    font-weight: 400;
                    font-size: 22px;
                    line-height: 33px;
                }

                input{
                    border: 1px solid #D6D6D6;
                    border-radius: 10px;
                    color: #353B48;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 22px;
                    padding: 11px 10px;
                    width: 100%;
                }
            }

            .buttons_block{
                display: flex;
                justify-content: flex-end;
                gap: 24px;

                .button{
                    border-radius: 10px;
                }
            }

            .resend_code_block{
                margin-top: 24px;

                p{
                    color: #718093;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 21px;
                    float: right;
                    margin-bottom: 0;

                    &:hover{
                        cursor: pointer;
                    }
                }
            }
        }
    }
</style>