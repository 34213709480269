<template>
    <div class="lk-page_booking">
        <p class="lk-page_booking-header">Брони</p>
        <div class="lk-page_booking-filters">
            <div class="lk-page_booking-filters_ones col-3" style="margin-right: 10px;">
                <label for="" class="filter_label">Номер брони</label>
            </div>
            <div class="lk-page_booking-filters_ones col-3" style="margin-right: 10px;">
                <label for="" class="filter_label">Отбор по дате формирования</label>
            </div>
            <div class="lk-page_booking-filters_ones col-4" style="margin-right: 10px;">
                <label for="" class="filter_label">Отбор плановой дате реализации объекта</label>
            </div>
            <div class="lk-page_booking-filters_ones" style="width: 254px;">
                <label for="" class="filter_label">Ответственный</label>
            </div>
            <div class="lk-page_booking-filters_ones col-3" style="margin-right: 10px;">
                <input 
                    type="text" 
                    placeholder="Номер брони"
                    v-model="form.id_1c"
                    @change="update"
                >
            </div>
            <div class="lk-page_booking-filters_ones col-3" style="margin-right: 10px;">
                <date-picker 
                    v-model="date" 
                    range 
                    placeholder="дд.мм.гг - дд.мм.гг"
                    @change="setDates"
                ></date-picker>
            </div>
            <div class="lk-page_booking-filters_ones col-4" style="margin-right: 10px;">
                <date-picker 
                    v-model="date_plan" 
                    range 
                    placeholder="дд.мм.гг - дд.мм.гг"
                    @change="setDatesPlan"
                ></date-picker>
            </div>
            <div class="lk-page_booking-filters_ones" style="width: 254px;">
                <!-- <input 
                    type="text" 
                    placeholder="Поиск по номеру заказа"
                > -->

                <div class="custom_select">
                    <div @click="openedCustomSelect = !openedCustomSelect" class="choosen_item">
                        <p>{{ fioForm ? fioForm : 'Выберите ответственного' }}</p>

                        <svg v-if="openedCustomSelect" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.29289 8.29289C5.68342 7.90237 6.31658 7.90237 6.70711 8.29289L12 13.5858L17.2929 8.29289C17.6834 7.90237 18.3166 7.90237 18.7071 8.29289C19.0976 8.68342 19.0976 9.31658 18.7071 9.70711L12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071L5.29289 9.70711C4.90237 9.31658 4.90237 8.68342 5.29289 8.29289Z" fill="#353B48"/>
                        </svg>

                        <svg v-if="!openedCustomSelect" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M18.7071 15.7071C18.3166 16.0976 17.6834 16.0976 17.2929 15.7071L12 10.4142L6.70711 15.7071C6.31658 16.0976 5.68342 16.0976 5.29289 15.7071C4.90237 15.3166 4.90237 14.6834 5.29289 14.2929L11.2929 8.29289C11.6834 7.90237 12.3166 7.90237 12.7071 8.29289L18.7071 14.2929C19.0976 14.6834 19.0976 15.3166 18.7071 15.7071Z" fill="#353B48"/>
                        </svg>
                    </div>
                    <div v-if="openedCustomSelect" @mouseleave="openedCustomSelect = false" class="items">
                        <p @click="changeSelectedFio('')">Выберите ответственного</p>
                        <p
                            v-for="opt, idx in fioList"
                            :key="idx"
                            @click="changeSelectedFio(opt)"
                        >
                            {{ opt }}
                        </p>
                    </div>
                </div>
            </div>

            <div class="lk-page_booking-filters_ones col-2 mr-2">
                <div class="filter_button" @click="isShowStatusList = !isShowStatusList">
                    <div class="status_filter_block">
                        <svg class="svg_filter" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M3 4.5C3 4.10218 3.15804 3.72064 3.43934 3.43934C3.72064 3.15804 4.10218 3 4.5 3H19.5C19.8978 3 20.2794 3.15804 20.5607 3.43934C20.842 3.72064 21 4.10218 21 4.5V6.586C20.9999 7.11639 20.7891 7.62501 20.414 8L15 13.414V20.838C15 21.0255 14.9521 21.2099 14.8608 21.3737C14.7695 21.5375 14.6379 21.6753 14.4783 21.7739C14.3188 21.8724 14.1368 21.9286 13.9494 21.9371C13.7621 21.9455 13.5757 21.9059 13.408 21.822L9.691 19.964C9.48337 19.8602 9.30875 19.7006 9.1867 19.5031C9.06466 19.3057 9.00001 19.0781 9 18.846V13.414L3.586 8C3.2109 7.62501 3.00011 7.11639 3 6.586V4.5ZM5 5V6.586L10.56 12.146C10.6994 12.2853 10.8101 12.4507 10.8856 12.6327C10.9611 12.8148 11 13.0099 11 13.207V18.382L13 19.382V13.207C13 12.809 13.158 12.427 13.44 12.147L19 6.585V5H5Z" fill="#050505"/>
                        </svg>
    
                        <p>Статус</p>
                    </div>
                    <svg class="svg_arrow" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.52729 5.52876C3.78764 5.26841 4.20975 5.26841 4.4701 5.52876L7.9987 9.05735L11.5273 5.52876C11.7876 5.26841 12.2098 5.26841 12.4701 5.52876C12.7305 5.78911 12.7305 6.21122 12.4701 6.47157L8.4701 10.4716C8.20975 10.7319 7.78764 10.7319 7.52729 10.4716L3.52729 6.47157C3.26694 6.21122 3.26694 5.78911 3.52729 5.52876Z" fill="#050505"/>
                    </svg>
                </div>
                <div 
                    v-if="isShowStatusList" 
                    class="filter_list"
                    @mouseleave="isShowStatusList = false"
                >
                    <div 
                        class="filter_list_row"
                        v-for="status in bookingStatuses"
                        :key="status.id"
                        @click="addStatusToForm(status.name)"
                    >
                        <svg v-if="!checkStatusFormList(status.name).value" class="svg_checkbox" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="1" y="1" width="14" height="14" rx="1" stroke="#718093" stroke-width="2"/>
                        </svg>

                        <svg v-if="checkStatusFormList(status.name).value" class="svg_checkbox" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="16" height="16" rx="2" fill="#ED1C24"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.8536 4.14645C14.0488 4.34171 14.0488 4.65829 13.8536 4.85355L6.85355 11.8536C6.65829 12.0488 6.34171 12.0488 6.14645 11.8536L2.64645 8.35355C2.45118 8.15829 2.45118 7.84171 2.64645 7.64645C2.84171 7.45118 3.15829 7.45118 3.35355 7.64645L6.5 10.7929L13.1464 4.14645C13.3417 3.95118 13.6583 3.95118 13.8536 4.14645Z" fill="white"/>
                        </svg>
                        <label :for="status.name">{{ status.name }}</label>
                    </div>
                </div>
            </div>

            <div class="lk-page_booking-filters_ones col-2 mr-2">
                <div class="filter_button" @click="isShowCompanyList = !isShowCompanyList">
                    <p>Организации</p>
                    <svg class="svg_arrow" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.52729 5.52876C3.78764 5.26841 4.20975 5.26841 4.4701 5.52876L7.9987 9.05735L11.5273 5.52876C11.7876 5.26841 12.2098 5.26841 12.4701 5.52876C12.7305 5.78911 12.7305 6.21122 12.4701 6.47157L8.4701 10.4716C8.20975 10.7319 7.78764 10.7319 7.52729 10.4716L3.52729 6.47157C3.26694 6.21122 3.26694 5.78911 3.52729 5.52876Z" fill="#050505"/>
                    </svg>
                </div>
                <div 
                    v-if="isShowCompanyList" 
                    class="filter_list" 
                    style="width: 170%;"
                    @mouseleave="isShowCompanyList = false"
                >
                    <div 
                        class="filter_list_row"
                        v-for="company in companies"
                        :key="company.id"
                        @click="addCompanyToForm(company.id_1c)"
                    >
                        <svg v-if="!checkCompanyFormList(company.id_1c).value" class="svg_checkbox" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="1" y="1" width="14" height="14" rx="1" stroke="#718093" stroke-width="2"/>
                        </svg>

                        <svg v-if="checkCompanyFormList(company.id_1c).value" class="svg_checkbox" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="16" height="16" rx="2" fill="#ED1C24"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.8536 4.14645C14.0488 4.34171 14.0488 4.65829 13.8536 4.85355L6.85355 11.8536C6.65829 12.0488 6.34171 12.0488 6.14645 11.8536L2.64645 8.35355C2.45118 8.15829 2.45118 7.84171 2.64645 7.64645C2.84171 7.45118 3.15829 7.45118 3.35355 7.64645L6.5 10.7929L13.1464 4.14645C13.3417 3.95118 13.6583 3.95118 13.8536 4.14645Z" fill="white"/>
                        </svg>
                        <label :for="company.name">{{ company.name }}</label>
                    </div>
                </div>
            </div>

            <div 
                v-for="statusFL in statusesFormList"
                :key="statusFL"
                class="lk-page_booking-filters_ones col-2 mr-2"
            >
                <div class="filter_button" style="background-color: #353B48; color: #fff;">
                    <p>{{ statusFL }}</p>
                    <svg @click="addStatusToForm(statusFL, 'btn')" width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18.2983 5.71022C18.2058 5.61752 18.0959 5.54397 17.9749 5.49379C17.8539 5.44361 17.7242 5.41778 17.5933 5.41778C17.4623 5.41778 17.3326 5.44361 17.2116 5.49379C17.0907 5.54397 16.9808 5.61752 16.8883 5.71022L11.9983 10.5902L7.10827 5.70022C7.01569 5.60764 6.90578 5.5342 6.78481 5.4841C6.66385 5.43399 6.5342 5.4082 6.40327 5.4082C6.27234 5.4082 6.14269 5.43399 6.02173 5.4841C5.90076 5.5342 5.79085 5.60764 5.69827 5.70022C5.60569 5.79281 5.53225 5.90272 5.48214 6.02368C5.43204 6.14464 5.40625 6.27429 5.40625 6.40522C5.40625 6.53615 5.43204 6.6658 5.48214 6.78677C5.53225 6.90773 5.60569 7.01764 5.69827 7.11022L10.5883 12.0002L5.69827 16.8902C5.60569 16.9828 5.53225 17.0927 5.48214 17.2137C5.43204 17.3346 5.40625 17.4643 5.40625 17.5952C5.40625 17.7262 5.43204 17.8558 5.48214 17.9768C5.53225 18.0977 5.60569 18.2076 5.69827 18.3002C5.79085 18.3928 5.90076 18.4662 6.02173 18.5163C6.14269 18.5665 6.27234 18.5922 6.40327 18.5922C6.5342 18.5922 6.66385 18.5665 6.78481 18.5163C6.90578 18.4662 7.01569 18.3928 7.10827 18.3002L11.9983 13.4102L16.8883 18.3002C16.9809 18.3928 17.0908 18.4662 17.2117 18.5163C17.3327 18.5665 17.4623 18.5922 17.5933 18.5922C17.7242 18.5922 17.8538 18.5665 17.9748 18.5163C18.0958 18.4662 18.2057 18.3928 18.2983 18.3002C18.3909 18.2076 18.4643 18.0977 18.5144 17.9768C18.5645 17.8558 18.5903 17.7262 18.5903 17.5952C18.5903 17.4643 18.5645 17.3346 18.5144 17.2137C18.4643 17.0927 18.3909 16.9828 18.2983 16.8902L13.4083 12.0002L18.2983 7.11022C18.6783 6.73022 18.6783 6.09022 18.2983 5.71022Z" fill="white"/>
                    </svg>
                </div>
            </div>
            <div class="lk-page_booking-filters_ones col-2">
                <div @click="clearAll" class="filter_button" style="justify-content: center; background-color: #fff;">
                    <p>Сбросить все</p>
                </div>
            </div>
        </div>
        <div class="lk-page_booking-list" id="booking_list" @wheel="checkPositionWindow">
            <div 
                class="lk-page_booking-ones"
                v-for="book, index in bookingList"
                :key="book.id"
            >
                <div class="lk-page_booking-ones_left-part">
                    <div class="first_row">
                        <p>{{ book.id_1c }}</p>
                        <p>{{ getCompanyName(book.client_id_1c) }}</p>
                    </div>

                    <p class="book_header">{{ book.object }}</p>
                    <p class="book_subheader">{{ book.address }}</p>
                    <p v-if="book.total_volume && book.total_volume > 0" class="book_volume">{{ book.total_volume }} м3</p>
                </div>
                <div class="lk-page_booking-ones_right-part">
                    <div>
                        <div 
                            class="order-status-button"
                            :class="[
                                book.status == 'Забронировано' || book.status == 'Забронированно' ? 'order-status-button_orange' : '',
                                book.status == 'Cделка проиграна' ? 'order-status-button_red' : '',
                                book.status == 'Заключена сделка' ? 'order-status-button_green' : '',
                                book.status == 'Пересечение' ? 'order-status-button_grey' : '',
                                book.status == 'Аннулирована' ? 'order-status-button_darkgrey' : '',
                            ]"
                        >
                            {{ book.status }}
                        </div>
                    </div>

                    <div class="right-part_subheader">
                        <p>Ответственный менеджер</p>
                    </div>
                    <div class="right-part_manager">
                        <p>{{ book.fio }}</p>
                    </div>
                    <div class="right-part_manager">
                        <p>{{ book.phone }}</p>
                    </div>
                </div>
                <div class="booking_grey_line"></div>
                <div @click="changeOpenDetails(index)" class="button_details">
                    <p>НОМЕНКЛАТУРА В ЗАКАЗЕ</p>

                    <svg v-if="index != indexOpenedNomenclature" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.29289 8.29289C5.68342 7.90237 6.31658 7.90237 6.70711 8.29289L12 13.5858L17.2929 8.29289C17.6834 7.90237 18.3166 7.90237 18.7071 8.29289C19.0976 8.68342 19.0976 9.31658 18.7071 9.70711L12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071L5.29289 9.70711C4.90237 9.31658 4.90237 8.68342 5.29289 8.29289Z" fill="#353B48"/>
                    </svg>

                    <svg v-if="index == indexOpenedNomenclature" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M18.7071 15.7071C18.3166 16.0976 17.6834 16.0976 17.2929 15.7071L12 10.4142L6.70711 15.7071C6.31658 16.0976 5.68342 16.0976 5.29289 15.7071C4.90237 15.3166 4.90237 14.6834 5.29289 14.2929L11.2929 8.29289C11.6834 7.90237 12.3166 7.90237 12.7071 8.29289L18.7071 14.2929C19.0976 14.6834 19.0976 15.3166 18.7071 15.7071Z" fill="#353B48"/>
                    </svg>
                </div>
                <div v-if="index == indexOpenedNomenclature" class="booking_nomen">
                    <div 
                        v-for="nomen, idx in book.nomenclatures" 
                        :key="idx"
                        class="booking_nomen_once"
                    >
                        <p>{{ nomen }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/ru'
import Vuex from 'vuex'
import Acl from '@/utils/acl'

export default {
    mixins: [Acl],
    components: {
        DatePicker,
    },
    data() {
        return {
            date: null,
            date_plan: null,
            isShowStatusList: false,
            isShowCompanyList: false,
            indexOpenedNomenclature: null,
            fioForm: "",
            fioList: [],
            openedCustomSelect: false,
        }
    },
    computed: {
        ...Vuex.mapGetters({
            orderStatuses: 'dictionary/orderStatuses',
            searchForm: 'reports/formBooking',
            bookingList: 'reports/bookingList',
            formCompanies: 'companies/form',
            user: 'auth/user',
            companies: 'companies/companies',
            bookingStatuses: 'dictionary/bookingStatuses',
        }),
        form() {
            return Object.assign({}, this.searchForm)
        },
        statusesFormList() {
            return this.form.status
        },
        searchCompaniesForm() {
			return Object.assign({}, this.formCompanies)
		},
    },
    watch: {
        isShowStatusList(val) {
            if (val == false) {
                this.update()
            }
        },
        isShowCompanyList(val) {
            if (val == false) {
                this.update()
            }
        },
    },
    mounted() {
        this.getCompaniesList()
        this.search()
            .then(() => {
                this.setFioList()
            })
    },
    destroyed() {
        this.clearAll()
    },
    methods: {
        ...Vuex.mapMutations({
            updateCompaniesForm: 'companies/updateForm',
            updateForm: 'reports/setBookingForm',
            pushCompanyForm: 'reports/pushCompanyForm',
            spliceCompanyForm: 'reports/spliceCompanyForm',
            pushStatusForm: 'reports/pushStatusForm',
            spliceStatusForm: 'reports/spliceStatusForm',
        }),
        ...Vuex.mapActions({
            search: 'reports/getBookingList',
            getCompanies: 'companies/getCompanies',
            searchNextPageBooking: 'reports/searchNextPageBooking',
        }),
        checkPositionWindow() {
            let positionScroll = document.getElementById('booking_list')
			if (Math.floor(positionScroll.scrollHeight - positionScroll.scrollTop) === positionScroll.clientHeight) {
				if (this.isLoading == true) {
					return
				}
				this.form.page += 1
				this.updateForm(this.form)
				this.searchNextPageBooking()
			}
		},
        setFioList() {
            let array = []
            this.bookingList.map((element) => {
                if (!this.checkIssetInArray(element.fio, array)) {
                    array.push(element.fio)
                }
            })
            this.fioList = array
        },
        checkIssetInArray(value, array) {
            let isset = false
            array.map((element) => {
                if (element == value) {
                    isset = true
                }
            })
            return isset
        },
        checkStatusFormList(string) {
            let isIsset = false
            let array = this.form.status
            let index = null
            array.map((element, idx) => {
                if (element == string) {
                    isIsset = true
                    index = idx
                }
            })
            return {value: isIsset, index: index}
        },
        checkCompanyFormList(id_1c) {
            let isIsset = false
            let array = this.form.company_id1cs
            let index = null
            if (Array.isArray(array)) {
                array.map((element, idx) => {
                    if (element == id_1c) {
                        isIsset = true
                        index = idx
                    }
                })
            }
            return {value: isIsset, index: index}
        },
        addCompanyToForm(id_1c) {
            let checkObject = this.checkCompanyFormList(id_1c)
            if (!checkObject.value) {
                this.pushCompanyForm(id_1c)
            }else{
                this.spliceCompanyForm(checkObject.index)
            }
            this.updateForm(this.form)
        },
        getCompaniesList() {
            if (!this.can('admin')) {
                let user_companies = this.user.user_link_company
                let array = []
                user_companies.map((element) => {
                    if(element.is_admin == true) {
                        array.push(element.company_id)
                    }
                })

                this.searchCompaniesForm.ids = array
                this.updateCompaniesForm(this.searchCompaniesForm)
            }else{
                this.searchCompaniesForm.ids = []
                this.updateCompaniesForm(this.searchCompaniesForm)
            }
            this.getCompanies()
        },
        addStatusToForm(string, type) {
            let checkObject = this.checkStatusFormList(string)
            if (!checkObject.value) {
                this.pushStatusForm(string)
            }else{
                this.spliceStatusForm(checkObject.index)
            }
            this.updateForm(this.form)
            if (type && type == 'btn') {
                this.search()
            }
        },
        changeOpenDetails(index) {
            if (this.indexOpenedNomenclature == index) {
                this.indexOpenedNomenclature = null
            }else{
                this.indexOpenedNomenclature = index
            }
        },
        update() {
            this.form.page = 1
            this.updateForm(this.form)
            this.search()
        },
        setDates() {
            if (this.date[0]) {
                this.form.date_start = this.date[0]
            }else{
                this.form.date_start = null
            }
            if (this.date[1]) {
                this.form.date_end = this.date[1]
            }else{
                this.form.date_end = null
            }
            this.update()
        },
        setDatesPlan() {
            if (this.date_plan[0]) {
                this.form.date_plan_start = this.date_plan[0]
            }else{
                this.form.date_plan_start = null
            }
            if (this.date_plan[1]) {
                this.form.date_plan_end = this.date_plan[1]
            }else{
                this.form.date_plan_end = null
            }
            this.update()
        },
        getCompanyName(id_1c) {
            let name = null
            this.companies.map((element) => {
                if (element.id_1c == id_1c) {
                    name = element.name
                }
            })
            return name 
        },
        clearAll() {
            this.form.page = 1
            this.form.limit = 50
            this.form.date_end = null
            this.form.date_start = null
            this.form.date_plan_end = null
            this.form.date_plan_start = null
            this.form.fio = []
            this.fioForm = ""
            this.date = null
            this.date_plan = null
            this.form.id_1c = ""
            this.form.status = []
            this.form.company_id1cs = []
            this.update()
        },
        changeSelectedFio(fio) {
            this.fioForm = fio
            if (this.fioForm) {
                this.form.fio = [this.fioForm]
            }else{
                this.form.fio = []
            }
            this.openedCustomSelect = false
            this.update()
        },
    },
}
</script>
<style lang="scss">
    .lk-page_booking{
        .lk-page_booking-header{
            margin-top: 50px;
            margin-bottom: 10px;

            font-weight: 700;
            font-size: 32px;
            line-height: 48px;
            color: black;
            margin-bottom: 30px;
        }
        .lk-page_booking-filters{
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 30px;

            .lk-page_booking-filters_ones{
                position: relative;

                .filter_label{
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 20px;
                    margin-bottom: 10px;
                }

                input{
                    width: 100%;
                    color: #718093;
                    border: 1px solid #D6D6D6;
                    border-radius: 10px;
                    padding: 11px 12px;
                    height: 48px;
                }

                select{
                    width: 100%;
                    color: #718093;
                    border: 1px solid #D6D6D6;
                    border-radius: 10px;
                    padding: 11px 12px;
                    height: 48px;
                    background-color: #fff;

                    &:active{
                        border-radius: 10px !important;
                    }
                }

                select::after{
                    color: red !important;
                }

                .mx-datepicker{
                    width: 100%;
                }
                
                .filter_button{
                    display: flex;
                    flex-wrap: nowrap;
                    align-items: center;
                    justify-content: space-between;

                    background-color: #F5F6FA;
                    color: black;

                    padding: 8px 20px;
                    border-radius: 10px;
                    margin-top: 10px;

                    p{
                        margin-bottom: 0;
                        font-weight: 600;
                        font-size: 18px;
                        line-height: 20px;
                    }

                    &:hover{
                        cursor: pointer;
                    }

                    .svg_filter{
                        margin-right: 4px;
                    }

                    .status_filter_block{
                        display: flex;
                        flex-wrap: nowrap;
                        align-items: center;
                    }
                }
                .filter_list{
                    display: flex;
                    flex-direction: column;
                    background-color: #fff;
                    border: 1px solid #D6D6D6;
                    border-radius: 10px;
                    padding: 8px 12px;
                    width: 120%;

                    position: absolute;
                    z-index: 5;

                    .filter_list_row{
                        display: flex;
                        flex-wrap: nowrap;
                        align-items: center;

                       .svg_checkbox{
                            margin-right: 10px;

                            &:hover{
                                cursor: pointer;
                            }
                       }
                    }
                }

                .custom_select{
                    position: relative;

                    .choosen_item{
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        border: 1px solid #D6D6D6;
                        border-radius: 10px;
                        padding: 2px 12px;

                        &:hover{
                            cursor: pointer;
                        }

                        p{
                            margin-bottom: 0;
                            color: #757575;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 20px;
                        }
                    }

                    .items{
                        position: absolute;
                        border: 1px solid #D6D6D6;
                        border-radius: 10px;
                        background-color: #fff;
                        max-height: 300px;
                        overflow-x: auto;

                        p{
                            padding: 6px 12px;
                            margin-bottom: 0;
                            color: #353B48;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 20px;

                            &:hover{
                                background-color: #eaeaea;
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
        }
        .lk-page_booking-list{
            display: flex;
            flex-direction: column;

            overflow-x: auto;
            max-height: 710px;

            .lk-page_booking-ones{
                display: flex;
                flex-wrap: wrap;
                border-radius: 5px;
                background-color: #F5F6FA;
                padding: 24px;
                margin-bottom: 20px;

                .lk-page_booking-ones_left-part{
                    display: flex;
                    flex-wrap: wrap;
                    flex-direction: column;

                    width: 80%;
                    margin-right: auto;
                    margin-bottom: 30px;

                    .first_row{
                        display: flex;
                        flex-wrap: nowrap;
                        gap: 12px;

                        font-weight: 400;
                        color: black;
                        font-size: 16px;
                        line-height: 24px;
                    }

                    .book_header{
                        font-weight: 700;
                        color: black;
                        font-size: 22px;
                        line-height: 33px;
                        margin-bottom: 10px;
                    }

                    .book_subheader{
                        color: #718093;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 20px;
                        margin-bottom: 10px;
                    }

                    .book_volume{
                        font-weight: 700;
                        font-size: 16px;
                        line-height: 24px;
                        color: #718093;
                        margin-bottom: 30px;
                    }

                    .order-number{
                        color: black;
                        font-weight: 700;
                        font-size: 16px;
                        line-height: 19px;
                        margin-bottom: 12px;
                        width: 100%;
                    }

                    .company-name{
                        color: #718093;
                        font-weight: 700;
                        font-size: 16px;
                        line-height: 19px;
                        width: 100%;
                    }
                }

                .lk-page_booking-ones_right-part{
                    width: 20%;
                    margin-left: auto;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                    margin-bottom: 30px;

                    .right-part_subheader{

                        p{
                            font-weight: 700;
                            font-size: 16px;
                            line-height: 24px;
                            color: black;
                            margin-bottom: 10px;
                            text-align: end;
                        }
                    }

                    .right-part_manager{

                        p{
                            color: black;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 14px;
                            margin-bottom: 10px;
                            text-align: end;
                        }
                    }

                    .order-status-button{
                        color: #fff;
                        padding: 4px 10px;
                        border-radius: 100px;
                        font-weight: 600;
                        font-size: 15px;
                        line-height: 18px;
                        margin-bottom: 19px;
                    }
    
                    .order-status-button_green{
                        background-color: #039B24;
                    }
                    .order-status-button_lightgreen{
                        background-color: #05DB41;
                    }
                    .order-status-button_lightblue{
                        background-color: #4BB5E3;
                    }
                    .order-status-button_blue{
                        background-color: #0071B5;
                    }
                    .order-status-button_purple{
                        background-color: #BD01DC;
                    }
                    .order-status-button_black{
                        background-color: black;
                    }
                    .order-status-button_red{
                        background-color: #ED1C24;
                    }
                    .order-status-button_yellow{
                        background-color: #C9A901;
                    }
                    .order-status-button_orange{
                        background-color: #D59009;
                    }
                    .order-status-button_grey{
                        background-color: #718093;
                    }
                    .order-status-button_darkgrey{
                        background-color: #343b47;
                    }
                }

                .order_button_block{
                    width: 100%;
                    display: flex;
                    justify-content: flex-end;

                    .order-button{
                        color: #fff;
                        background-color: black;
                        padding: 10px 30px;
                        border-radius: 10px;

                        font-weight: 700;
                        font-size: 16px;
                        line-height: 24px;

                        display: flex;
                        flex-wrap: nowrap;
                        align-items: center;
                        gap: 20px;

                        &:hover{
                            cursor: pointer;
                        }
                    }
                }

                .booking_grey_line{
                    background-color: #0000001A;
                    height: 1px;
                    width: 100%;
                    margin-bottom: 30px;
                }

                .button_details{
                    display: flex;
                    gap: 20px;
                    width: 30%;
                    margin-bottom: 20px;

                    &:hover{
                        cursor: pointer;
                    }

                    p{
                        font-weight: 700;
                        font-size: 16px;
                        line-height: 24px;
                        color: black;
                        margin-bottom: 0;
                    }
                }

                .booking_nomen{
                    width: 100%;

                    .booking_nomen_once{
                        margin-bottom: 20px;

                        p{
                            margin-bottom: 0;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 24px;
                            color: black;
                        }
                    }
                    
                }
            }
        }
    }
</style>