import Vuex from 'vuex'
export default {
    computed: {
        ...Vuex.mapGetters({
            roles: 'auth/roles',
        }),
    },
    methods: {
        can(val) {
            var rl = this.roles
            if (!rl) {
                return false
            }
            for (let i = 0; i < rl.length; i++) {
                if (rl[i] == val) {
                    return true 
                }
            }
        }
    }
}