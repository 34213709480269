<template>
    <div class="lk-page_companies">
        <div class="lk-page_companies-header_buttons">
            <p class="lk-page_companies-header">Мои организации</p>

            <button v-if="companies.length != 0" @click="openAddModal" class="button button--primary add_button">
                + Добавить организацию
            </button>
        </div>
        <div class="lk-page_companies-filters">
            <div class="lk-page_companies-filters_ones" style="margin-right: 10px; width: 68%;">
                <input 
                    type="text" 
                    class="filters_search_input" 
                    placeholder="Поиск" 
                    v-model="searchForm.query" 
                    @change="search" 
                    @keydown.enter="search" 
                    @paste="pasteQuery"
                >
                <svg class="svg_search" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.96907 9.96387L14 14M11.3333 6.66667C11.3333 9.244 9.244 11.3333 6.66667 11.3333C4.08934 11.3333 2 9.244 2 6.66667C2 4.08934 4.08934 2 6.66667 2C9.244 2 11.3333 4.08934 11.3333 6.66667Z" stroke="#718093" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>
            <div class="lk-page_companies-filters_ones" style="margin-right: 10px; width: 15%;">
                <input type="text" placeholder="ИНН" v-model="searchForm.inn" @keypress.enter="search">
            </div>
            <div class="lk-page_companies-filters_ones" style="width: 15%;">
                <input type="text" placeholder="id 1c" v-model="searchForm.id_1c" @keypress.enter="search">
            </div>
        </div>
        <div v-if="companies.length != 0" class="lk-page_companies-button-block">
            <div class="lk-page_companies-button-block_ones" :class="activeFilter == 1 ? 'lk-page_companies-button-block_ones-active' : ''" @click="changeActiveFilter(1)">Активные</div>
            <div class="lk-page_companies-button-block_ones" :class="activeFilter == 2 ? 'lk-page_companies-button-block_ones-active' : ''" @click="changeActiveFilter(2)">Ожидание доступа</div>
            <div class="lk-page_companies-button-block_ones" :class="activeFilter == 3 ? 'lk-page_companies-button-block_ones-active' : ''" @click="changeActiveFilter(3)">Недоступные</div>
        </div>

        <div v-if="companies.length == 0" class="empty_orders_block">
            <!-- <img src="@/assets/img/empty_orders.png" alt=""> -->
            <p class="empty_orders_header">У вас пока нет привязанных организаций!</p>
            <p class="empty_orders_subheader">Добавьте компания, чтобы покупать как юр. лицо</p>
            <button class="button button--primary" @click="push('catalog')">+ Добавить организацию</button>
        </div>
        
        <div class="lk-page_companies-list">
            <single 
                v-for="company in companies"
                :key="company.id"
                :element="company"
                :activeFilter="activeFilter"
                @openEditModal="openEditModal" 
                @openDeleteModal="openDeleteModal" 
                @openRequestModal="openRequestModal" 
            />
        </div>

        <edit-company v-if="isShowEditModal" @closeEditModal="closeEditModal" />
        <delete-company v-if="isShowDeleteModal" @closeDeleteModal="closeDeleteModal" />
        <request-company v-if="isShowRequestModal" @closeRequestModal="closeRequestModal" />
        <add-company v-if="isShowAddModal" @closeAddModal="closeAddModal" />
    </div>
</template>
<script>
import single from './single.vue'
import editCompany from './modals/editCompany.vue'
import deleteCompany from './modals/deleteCompany.vue'
import requestCompany from './modals/sendRequest.vue'
import addCompany from './modals/addCompany.vue'
import Vuex from 'vuex'

export default {
    components: {
        single,
        editCompany,
        deleteCompany,
        requestCompany,
        addCompany,
    },
    data() {
        return {
            isShowEditModal: false,
            isShowDeleteModal: false,
            isShowRequestModal: false,
            isShowAddModal: false,
            activeFilter: 1,
            searchForm: {
                email: "",
                excludedIds: [],
                id_1c: "",
                ids: [],
                inn: "",
                kpp: "",
                limit: null,
                name: "",
                page: 1,
                query: "",
                root_id: ""
            }, 
        }
    },
    computed: {
        ...Vuex.mapGetters({
            companies: 'companies/currentCompanies',
        }),
    },
    mounted() {
        this.search()
    },
    methods: {
        ...Vuex.mapActions(({
            searchCompany: 'companies/search',
        })),
        ...Vuex.mapMutations({
            setDeleteCompanyId: 'companies/setDeleteCompanyId',
            setEditCompanyElement: 'companies/setEditCompanyElement',
        }),
        search() {
            this.searchCompany(this.searchForm)
        },
        openEditModal() {
            this.isShowEditModal = true
        },
        closeEditModal() {
            this.setEditCompanyElement({})
            this.isShowEditModal = false
            this.search()
        },
        openDeleteModal() {
            this.isShowDeleteModal = true
        },
        closeDeleteModal() {
            this.isShowDeleteModal = false
            this.setDeleteCompanyId(null)
        },
        openRequestModal() {
            this.isShowRequestModal = true
        },
        closeRequestModal() {
            this.isShowRequestModal = false
        },
        changeActiveFilter(num) {
            this.activeFilter = num
        },
        openAddModal() {
            this.isShowAddModal = true
        },
        closeAddModal() {
            this.isShowAddModal = false
            this.search()
        },
        pasteQuery() {

        },
        getAllOrders(){

        },
    },
}
</script>