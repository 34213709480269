<template>
    <div class="order_decline_modal">
        <div class="modal-iframe">
            <div class="modal modal--form modal--opened modal--visible" id="modal_callback">
                <div class="modal-container">
                    <div @click="close" class="modal-button-close">
                        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18.7983 5.70998C18.7058 5.61728 18.5959 5.54373 18.4749 5.49355C18.3539 5.44337 18.2242 5.41754 18.0933 5.41754C17.9623 5.41754 17.8326 5.44337 17.7116 5.49355C17.5907 5.54373 17.4808 5.61728 17.3883 5.70998L12.4983 10.59L7.60827 5.69998C7.51569 5.6074 7.40578 5.53396 7.28481 5.48385C7.16385 5.43375 7.0342 5.40796 6.90327 5.40796C6.77234 5.40796 6.64269 5.43375 6.52173 5.48385C6.40076 5.53396 6.29085 5.6074 6.19827 5.69998C6.10569 5.79256 6.03225 5.90247 5.98214 6.02344C5.93204 6.1444 5.90625 6.27405 5.90625 6.40498C5.90625 6.53591 5.93204 6.66556 5.98214 6.78652C6.03225 6.90749 6.10569 7.0174 6.19827 7.10998L11.0883 12L6.19827 16.89C6.10569 16.9826 6.03225 17.0925 5.98214 17.2134C5.93204 17.3344 5.90625 17.464 5.90625 17.595C5.90625 17.7259 5.93204 17.8556 5.98214 17.9765C6.03225 18.0975 6.10569 18.2074 6.19827 18.3C6.29085 18.3926 6.40076 18.466 6.52173 18.5161C6.64269 18.5662 6.77234 18.592 6.90327 18.592C7.0342 18.592 7.16385 18.5662 7.28481 18.5161C7.40578 18.466 7.51569 18.3926 7.60827 18.3L12.4983 13.41L17.3883 18.3C17.4809 18.3926 17.5908 18.466 17.7117 18.5161C17.8327 18.5662 17.9623 18.592 18.0933 18.592C18.2242 18.592 18.3538 18.5662 18.4748 18.5161C18.5958 18.466 18.7057 18.3926 18.7983 18.3C18.8909 18.2074 18.9643 18.0975 19.0144 17.9765C19.0645 17.8556 19.0903 17.7259 19.0903 17.595C19.0903 17.464 19.0645 17.3344 19.0144 17.2134C18.9643 17.0925 18.8909 16.9826 18.7983 16.89L13.9083 12L18.7983 7.10998C19.1783 6.72998 19.1783 6.08998 18.7983 5.70998Z" fill="black"/>
                        </svg>
                    </div>
                    <div class="state">
                        <div class="state-item">
                            <div class="form" name="callback-form-popup">
                                <p class="modal_header">
                                    Вы хотите отклонить заказ?
                                </p>

                                <p class="modal_subheader">
                                    Укажите причину отмены
                                </p>

                                <textarea 
                                    name="" 
                                    id=""
                                    v-model="reason"
                                    placeholder="Введите сообщение"
                                    rows="8"
                                ></textarea>

                                <button 
                                    class="button button--primary"
                                    @click="send"    
                                >
                                    Отменить заказ
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Vuex from 'vuex'

export default {
    data() {
        return {
            reason: null,
        }
    },
    computed: {
        ...Vuex.mapGetters({
            order: 'basket/currentOrder',
        }),
    },
    methods: {
        ...Vuex.mapMutations({
            setShowDeclineOrderModal: 'basket/setShowDeclineOrderModal',
        }),
        ...Vuex.mapActions({
            cancelOrder: 'basket/cancelOrder',
            getOne: 'basket/getOne',
        }),
        close() {
            this.reason = null
            this.setShowDeclineOrderModal(false)
        },
        send() {
            let testObject = {
                new_status: 'Отклонен',
                order_id_1c: this.order.order_id_1c,
                id: this.order.id,
                comment: this.reason,
            }
            this.cancelOrder(testObject)
                .then(() => {
                    this.close()
                    this.getOne(this.order.id)
                })
        },
    },
}
</script>
<style lang="scss">
    .order_decline_modal{
        .modal-container{
            padding: 24px !important;
            max-width: 549px !important;
            position: relative;

            .modal-button-close{
                position: absolute;
                top: 24px;
                right: 24px;

                &:hover{
                    cursor: pointer;
                }
            }

            .form{
                .modal_header{
                    font-weight: 700;
                    font-size: 22px;
                    line-height: 33px;
                    margin-bottom: 24px;
                    color: black;
                }

                .modal_subheader{
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 24px;
                    color: black;
                    margin-bottom: 12px;
                }

                .modal_message{
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 21px;
                    margin-bottom: 24px;
                    color: black;
                }

                .button{
                    border-radius: 10px;
                    padding: 12px 31px;
                    margin-bottom: 12px;

                    float: inline-end;
                }

                textarea{
                    width: 100%;
                    margin-bottom: 24px;

                    font-weight: 400;
                    font-size: 16px;
                    line-height: 22px;
                    color: #353B48;

                    border: 1px solid #D6D6D6;
                    border-radius: 10px;
                    padding: 10px;
                }
            }
        } 
    }
</style>