<template>
    <div class="modal-iframe">
          <div class="modal modal--form modal--opened modal--visible" id="modal_callback">
              <div class="modal-container">
                  <div class="state">
                      <div class="state-item">
                          <div class="form" name="callback-form-popup">
                              <p v-if="typeConfirm == 10">
                                Заказ успешно подтверждён
                              </p>
                              <p v-else>
                                Доставка заказа успешно подтверждена
                              </p>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </template>
  <script>
  import Vuex from 'vuex'
  
  export default {
        props: {
            typeConfirm: {
                type: Number,
                default: 10
            }
        },
      computed: {
          ...Vuex.mapGetters({
              sendedMessage: 'app/sendedMessage',
          }),
      },
      methods: {
          ...Vuex.mapMutations({
              setShowLastConfirmOrder: 'basket/setShowLastConfirmOrder',
          }),
          close() {
              this.setShowLastConfirmOrder(false)
          }
      }
  }
  </script>
  <style scoped>
      .form p{
          text-align: center;
      }
  </style>