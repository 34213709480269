<template>
    <div class="modal-iframe">
          <div class="modal modal--form modal--opened modal--visible" id="modal_callback">
              <div class="modal-container">
                  <button class="modal-close" @click="close">×</button>
                  <div class="state">
                      <div class="state-item">
                          <div class="form" name="callback-form-popup">
                                <p>
                                    {{ message }} 
                                </p>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </template>
  <script>
  import Vuex from 'vuex'
  import Functions from '@/utils/functions'
  
  export default {
    mixins: [Functions],
    computed: {
        ...Vuex.mapGetters({
            message: 'auth/message',
        }),
    },
    methods: {
        ...Vuex.mapMutations({
            
        }),
        close() {
            this.$emit('closeErrorModal')
        }
      }
  }
</script>