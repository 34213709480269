<template>
    <div v-if="(activeFilter == 1 && (element.access > 20 && element.access < 60)) || (activeFilter == 2 && (element.access == 10 || element.access == 20 || element.access == 70)) || (activeFilter == 3 && element.access == 60)" class="lk-page_companies-employees-table_body">
        <div class="lk-page_companies-employees-table_body_once" :style="activeFilter == 1 ? 'width: 25%;' : 'width: 40%;'">
            <p>{{ element.surname + ' ' + element.name + ' ' + element.midname }}</p>
        </div>
        <div class="lk-page_companies-employees-table_body_once" :style="activeFilter == 1 ? 'width: 20%;' : 'width: 40%;'">
            <p>{{ element.email }}</p>
        </div>
        <div v-if="activeFilter == 1" class="lk-page_companies-employees-table_body_once" style="width: 15%;">
            <p>{{ createUserDate }}</p>
        </div>
        <div v-if="activeFilter == 1" class="lk-page_companies-employees-table_body_once" style="width: 15%;">
            <p>{{ createLinkDate }}</p>
        </div>
        <div v-if="activeFilter == 1" class="lk-page_companies-employees-table_body_once" style="width: 20%;">
            <p>{{ getNameOfType(element.access) }}</p>
        </div>
        <div v-if="activeFilter == 1" class="lk-page_companies-employees-table_body_once" style="width: 5%; text-align: end;">
            <svg v-if="currentCompanyAccess == 50 || can('admin')" @click="isShowEventBlock = !isShowEventBlock" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.6875 5.41406C10.6875 5.76216 10.8258 6.096 11.0719 6.34214C11.3181 6.58828 11.6519 6.72656 12 6.72656C12.3481 6.72656 12.6819 6.58828 12.9281 6.34214C13.1742 6.096 13.3125 5.76216 13.3125 5.41406C13.3125 5.06597 13.1742 4.73213 12.9281 4.48598C12.6819 4.23984 12.3481 4.10156 12 4.10156C11.6519 4.10156 11.3181 4.23984 11.0719 4.48598C10.8258 4.73213 10.6875 5.06597 10.6875 5.41406ZM10.6875 11.9766C10.6875 12.3247 10.8258 12.6585 11.0719 12.9046C11.3181 13.1508 11.6519 13.2891 12 13.2891C12.3481 13.2891 12.6819 13.1508 12.9281 12.9046C13.1742 12.6585 13.3125 12.3247 13.3125 11.9766C13.3125 11.6285 13.1742 11.2946 12.9281 11.0485C12.6819 10.8023 12.3481 10.6641 12 10.6641C11.6519 10.6641 11.3181 10.8023 11.0719 11.0485C10.8258 11.2946 10.6875 11.6285 10.6875 11.9766ZM10.6875 18.5391C10.6875 18.8872 10.8258 19.221 11.0719 19.4671C11.3181 19.7133 11.6519 19.8516 12 19.8516C12.3481 19.8516 12.6819 19.7133 12.9281 19.4671C13.1742 19.221 13.3125 18.8872 13.3125 18.5391C13.3125 18.191 13.1742 17.8571 12.9281 17.611C12.6819 17.3648 12.3481 17.2266 12 17.2266C11.6519 17.2266 11.3181 17.3648 11.0719 17.611C10.8258 17.8571 10.6875 18.191 10.6875 18.5391Z" fill="black"/>
            </svg>

            <div v-if="isShowEventBlock" class="company_event_list">
                <div @click="openDeleteModal(element.id)" class="company_event_list_ones">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7 21C6.45 21 5.97933 20.8043 5.588 20.413C5.19667 20.0217 5.00067 19.5507 5 19V6H4V4H9V3H15V4H20V6H19V19C19 19.55 18.8043 20.021 18.413 20.413C18.0217 20.805 17.5507 21.0007 17 21H7ZM17 6H7V19H17V6ZM9 17H11V8H9V17ZM13 17H15V8H13V17Z" fill="black"/>
                    </svg>
                    <p>Удалить сотрудника</p>
                </div>
                <div @click="openAccessModal(element)" class="company_event_list_ones">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14.06 9.02L14.98 9.94L5.92 19H5V18.08L14.06 9.02ZM17.66 3C17.41 3 17.15 3.1 16.96 3.29L15.13 5.12L18.88 8.87L20.71 7.04C20.8027 6.94749 20.8762 6.8376 20.9264 6.71662C20.9766 6.59565 21.0024 6.46597 21.0024 6.335C21.0024 6.20403 20.9766 6.07435 20.9264 5.95338C20.8762 5.8324 20.8027 5.72251 20.71 5.63L18.37 3.29C18.17 3.09 17.92 3 17.66 3ZM14.06 6.19L3 17.25V21H6.75L17.81 9.94L14.06 6.19Z" fill="black"/>
                    </svg>
                    <p>Изменить тип доступа</p>
                </div>
            </div>
        </div>
        <div v-if="activeFilter == 2 && (element.access == 10 || element.access == 20)" class="lk-page_companies-employees-table_body_once" style="width: 20%; text-align: end;">
            <p class="access_ones_header">Приглашение отправлено</p>
            <p v-if="currentCompanyAccess == 50 || can('admin')" class="access_ones_subheader" @click="resendEmployee">Отправить повторно</p>
        </div>
        <div v-if="activeFilter == 2 && element.access == 70" class="lk-page_companies-employees-table_body_once" style="width: 20%; text-align: end;">
            <p class="access_ones_header">Запросили доступ</p>
            <button v-if="currentCompanyAccess == 50 || can('admin')" class="button button--primary button-black" @click="isShowEventBlock = !isShowEventBlock">Открыть доступ</button>

            <div v-if="isShowEventBlock" class="company_event_list company_event_list_low">
                <div @click="changeEmployeeAccess(40)" class="company_event_list_ones">
                    <p>Полный доступ</p>
                </div>
                <div @click="changeEmployeeAccess(30)" class="company_event_list_ones">
                    <p>Ограниченный доступ</p>
                </div>
            </div>
        </div>
        <div v-if="activeFilter == 3" class="lk-page_companies-employees-table_body_once" style="width: 20%; text-align: end;">
            <button v-if="currentCompanyAccess == 50 || can('admin')" class="button button--primary button-black" @click="isShowEventBlock = !isShowEventBlock">
                Открыть доступ
                <svg v-if="isShowEventBlock" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_1015_11825)">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M11.2927 8.79363C11.4802 8.60616 11.7345 8.50085 11.9997 8.50085C12.2648 8.50085 12.5191 8.60616 12.7067 8.79363L18.3637 14.4506C18.4592 14.5429 18.5353 14.6532 18.5878 14.7752C18.6402 14.8972 18.6678 15.0285 18.6689 15.1612C18.6701 15.294 18.6448 15.4257 18.5945 15.5486C18.5442 15.6715 18.4699 15.7831 18.3761 15.877C18.2822 15.9709 18.1705 16.0452 18.0476 16.0955C17.9247 16.1457 17.793 16.171 17.6603 16.1699C17.5275 16.1687 17.3963 16.1411 17.2743 16.0887C17.1522 16.0363 17.0419 15.9601 16.9497 15.8646L11.9997 10.9146L7.04966 15.8646C6.86105 16.0468 6.60845 16.1476 6.34626 16.1453C6.08406 16.143 5.83325 16.0379 5.64784 15.8525C5.46243 15.667 5.35726 15.4162 5.35498 15.154C5.3527 14.8918 5.4535 14.6392 5.63566 14.4506L11.2927 8.79363Z" fill="white"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_1015_11825">
                            <rect width="24" height="24" fill="white" transform="matrix(-1 0 0 -1 24 24.5)"/>
                        </clipPath>
                    </defs>
                </svg>
                <svg v-else width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_1015_11835)">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.7073 16.2064C12.5198 16.3938 12.2655 16.4992 12.0003 16.4992C11.7352 16.4992 11.4809 16.3938 11.2933 16.2064L5.63634 10.5494C5.54083 10.4571 5.46465 10.3468 5.41224 10.2248C5.35983 10.1028 5.33225 9.97155 5.33109 9.83877C5.32994 9.70599 5.35524 9.57431 5.40552 9.45141C5.4558 9.32852 5.53006 9.21686 5.62395 9.12297C5.71784 9.02908 5.82949 8.95483 5.95239 8.90454C6.07529 8.85426 6.20696 8.82896 6.33974 8.83012C6.47252 8.83127 6.60374 8.85886 6.72575 8.91126C6.84775 8.96367 6.9581 9.03986 7.05034 9.13537L12.0003 14.0854L16.9503 9.13537C17.1389 8.95321 17.3915 8.85241 17.6537 8.85469C17.9159 8.85697 18.1668 8.96214 18.3522 9.14755C18.5376 9.33296 18.6427 9.58377 18.645 9.84597C18.6473 10.1082 18.5465 10.3608 18.3643 10.5494L12.7073 16.2064Z" fill="white"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_1015_11835">
                            <rect width="24" height="24" fill="white" transform="translate(0 0.5)"/>
                        </clipPath>
                    </defs>
                </svg>

            </button>

            <div v-if="isShowEventBlock" class="company_event_list company_event_list_low">
                <div @click="changeEmployeeAccess(40)" class="company_event_list_ones">
                    <p>Полный доступ</p>
                </div>
                <div @click="changeEmployeeAccess(30)" class="company_event_list_ones">
                    <p>Ограниченный доступ</p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Vuex from 'vuex'
import Functions from '@/utils/functions'
import Acl from '@/utils/acl'

export default {
    props: {
        element: {
            type: Object,
            default: () => {}
        },
        activeFilter: {
            type: Number,
            default: 1
        },
        currentCompanyAccess: {
            type: Number,
            default: 0
        },
    },
    mixins: [Functions, Acl],
    data() {
        return {
            isShowEventBlock: false,
        }
    },
    computed: {
        ...Vuex.mapGetters({
            users: 'users/users',
            companyTypes: 'dictionary/companyTypes',
        }),
        createUserDate() {
            let date = null
            this.users.map((el) => {
                if (el.id == this.element.user_id) {
                    date = el.created_at
                }
            })
            if (date) {
                date = this.foramtDateFromISO(date)
            }
            return date
        },
        createLinkDate() {
            let date = null
            if (this.element.created_at) {
                date = this.foramtDateFromISO(this.element.created_at)
            }
            return date
        },
        companyId() {
            return this.$route.params.id
        },
    },
    methods: {
        ...Vuex.mapMutations({
            setDeleteEmployeeId: 'companies/setDeleteEmployeeId',
            setLinkObject: 'companies/setLinkObject',
        }),
        ...Vuex.mapActions({
            changeAccess: 'companies/changeAccess',
            addEmployee: 'companies/addEmployee',
        }),
        changeEmployeeAccess(val) {
            let date = new Date(Date.now())
            date = date.toISOString()
            this.changeAccess({
                id: this.element.id,
                date: date,
                access: val,
            })
                .then(() => {
                    this.isShowEventBlock = false
                    this.$emit('searchUsersDefault')
                })
        },
        resendEmployee() {
            this.addEmployee({
                company_id: Number(this.companyId),
                email: this.element.email,
                access: this.element.access,
                text: '',
                surname: this.element.surname,
                name: this.element.name,
                midname: this.element.midname,
            })
                .then(() => {
                    console.log('resend email success')
                })
        },
        openDeleteModal(id) {
            this.isShowEventBlock = false
            this.setDeleteEmployeeId(id)
            this.$emit('openDeleteModal')
        },
        openAccessModal(object) {
            this.isShowEventBlock = false
            this.setLinkObject(object)
            this.$emit('openAccessModal')
        },
        getNameOfType(type) {
            let name = null
            this.companyTypes.map((el) => {
                if (el.id == type) {
                    name = el.name
                }
            })
            return name
        },
    }
}
</script>

<style>

</style>