<template>
    <main class="main">
        <div class="section section--no-pt">
            <div class="container-l lk-page">
                <div class="lk-navigation-block">
                    <div class="lk-navigation-header-block">
                        <p class="lk-navigation-header">Личный кабинет</p>

                        <div class="right_part">
                            <div v-if="countNotifications > 0" class="notification_block">
                                <p>У вас новое уведомление!</p>
                            </div>

                            <div @click="push('lk/notifications')" class="svg_block">
                                <svg style="cursor: pointer;" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_1_16513)">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.00066 9C5.00066 7.14348 5.73816 5.36301 7.05092 4.05025C8.36367 2.7375 10.1441 2 12.0007 2C13.8572 2 15.6377 2.7375 16.9504 4.05025C18.2632 5.36301 19.0007 7.14348 19.0007 9V12.764L20.8227 16.408C20.9065 16.5757 20.9461 16.7621 20.9377 16.9494C20.9293 17.1368 20.8731 17.3188 20.7745 17.4783C20.6759 17.6379 20.5382 17.7695 20.3744 17.8608C20.2106 17.9521 20.0262 18 19.8387 18H15.8747C15.6522 18.8582 15.1511 19.6183 14.4499 20.1609C13.7488 20.7035 12.8873 20.9979 12.0007 20.9979C11.1141 20.9979 10.2526 20.7035 9.5514 20.1609C8.85023 19.6183 8.3491 18.8582 8.12666 18H4.16266C3.97514 18 3.79072 17.9521 3.62693 17.8608C3.46314 17.7695 3.3254 17.6379 3.22681 17.4783C3.12822 17.3188 3.07204 17.1368 3.06361 16.9494C3.05518 16.7621 3.09479 16.5757 3.17866 16.408L5.00066 12.764V9ZM10.2687 18C10.4442 18.304 10.6967 18.5565 11.0007 18.732C11.3047 18.9075 11.6496 18.9999 12.0007 18.9999C12.3517 18.9999 12.6966 18.9075 13.0006 18.732C13.3046 18.5565 13.5571 18.304 13.7327 18H10.2687ZM12.0007 4C10.6746 4 9.40281 4.52678 8.46513 5.46447C7.52745 6.40215 7.00066 7.67392 7.00066 9V12.764C7.00064 13.0743 6.9284 13.3804 6.78966 13.658L5.61966 16H18.3827L17.2127 13.658C17.0736 13.3805 17.001 13.0744 17.0007 12.764V9C17.0007 7.67392 16.4739 6.40215 15.5362 5.46447C14.5985 4.52678 13.3267 4 12.0007 4Z" fill="white"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_1_16513">
                                            <rect width="24" height="24" fill="white"/>
                                        </clipPath>
                                    </defs>
                                </svg>
    
                                <svg v-if="countNotifications > 0" class="black_circle" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="6" cy="6" r="6" fill="black"/>
                                </svg>
                                <p v-if="countNotifications > 0" class="text_circle">{{ countNotifications }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="lk-navigation-list-block">
                        <div class="lk-navigation-list-once">
                            <p 
                                class="lk-navigation-list-once-name"
                                @click="push('lk/main')"
                            >
                                ГЛАВНАЯ
                            </p>
                        </div>
                        <div 
                            @mouseover="showOrderSubsLinks"
                            @mouseleave="closeOrderSubsLink" 
                            class="lk-navigation-list-once"
                        >
                            <p 
                                class="lk-navigation-list-once-name"
                                @click="pushToAllOrders"
                            >
                                ЗАКАЗЫ
                            </p>
                            <div 
                                v-if="isShowOrderSubs"
                                @mouseover="showOrderSubsLinks"
                                @mouseleave="closeOrderSubsLink" 
                                class="lk-navigation-list-once_sublinks"
                            >
                                <p @click="pushToAllOrders">Все</p>
                                <p @click="pushToActiveOrders">Активные</p>
                                <p @click="pushToEndedOrders">Завершенные</p>
                            </div>
                        </div>
                        <div 
                            @mouseover="isShowCompanySubs = true" 
                            @mouseleave="isShowCompanySubs = false" 
                            class="lk-navigation-list-once"
                            @click="push('lk/companies')"
                        >
                            <p class="lk-navigation-list-once-name">МОИ ОРГАНИЗАЦИИ</p>
                            <div 
                                v-if="isShowCompanySubs" 
                                class="lk-navigation-list-once_sublinks"
                            >
                                <p>Все организации</p>
                                <p v-for="com in companies" :key="com.id">{{ com.name }}</p>
                            </div>
                        </div>
                        <div 
                            @mouseover="isShowDocumentSubs = true" 
                            @mouseleave="isShowDocumentSubs = false"     
                            class="lk-navigation-list-once"
                        >
                            <p @click="push('lk/documents')" class="lk-navigation-list-once-name">ДОКУМЕНТЫ</p>
                            <div 
                                v-if="isShowDocumentSubs" 
                                class="lk-navigation-list-once_sublinks"
                            >
                                <p @click="push('lk/documents/act')">Акт сверки</p>
                                <p @click="push('lk/documents/price')">Прайс-лист</p>
                                <p @click="push('lk/documents/certificates')">Сертификаты</p>
                                <p @click="push('lk/documents/guaranteestalon')">Гарантийные талоны</p>
                            </div>
                        </div>
                        <div 
                            @mouseover="isShowInstructionSubs = true" 
                            @mouseleave="isShowInstructionSubs = false" 
                            class="lk-navigation-list-once"
                        >
                            <p @click="push('lk/instructions')" class="lk-navigation-list-once-name">ИНСТРУКЦИИ</p>
                            <div 
                                v-if="isShowInstructionSubs" 
                                class="lk-navigation-list-once_sublinks"
                            >
                                <p @click="push('lk/instructions')">Все инструкции</p>
                                <p @click="push('lk/instructions/rules')">Правила транспортировки</p>
                            </div>
                        </div>
                        <div @click="push('lk/booking')" class="lk-navigation-list-once">
                            <p class="lk-navigation-list-once-name">БРОНИ</p>
                        </div>
                    </div>
                </div>

                <main-page v-if="key == 'main' || !key" />
                <orders-page v-if="key == 'orders'" />
                <single-order v-if="key == 'single-order'" />
                <booking-page v-if="key == 'booking'" />
                <instructions-page v-if="key == 'instructions'" />
                <documents-page v-if="key == 'documents'" />
                <profile-page v-if="key == 'profile'" />
                <companies-page v-if="key == 'companies'" />
                <employees-page v-if="key == 'employees'" />
                <notif-page v-if="key == 'notifications'" />
            </div>
        </div>
    </main>
</template>
<script>
import Vuex from 'vuex'
import Functions from '@/utils/functions'
import mainPage from './main.vue'
import ordersPage from './orders.vue'
import singleOrder from './singleOrder.vue'
import bookingPage from './booking.vue'
import instructionsPage from './instructions.vue'
import documentsPage from './documents.vue'
import profilePage from './profile.vue'
import companiesPage from './companies/index.vue'
import employeesPage from './companies/employees.vue'
import notifPage from './notifications.vue'

export default {
    mixins: [Functions],
    components: {
        mainPage,
        ordersPage,
        singleOrder,
        bookingPage,
        instructionsPage,
        documentsPage,
        profilePage,
        companiesPage,
        employeesPage,
        notifPage,
    },
    data() {
        return {
            isShowOrderSubs: false,
            isShowCompanySubs: false,
            isShowDocumentSubs: false,
            isShowInstructionSubs: false,

            idTimerOrderSubs: 0,
            searchFormB: {
                email: "",
                excludedIds: [],
                id_1c: "",
                ids: [],
                inn: "",
                kpp: "",
                limit: null,
                name: "",
                page: 1,
                query: "",
                root_id: ""
            },

            ws: null,
        }
    },
    computed: {
        ...Vuex.mapGetters({
            pagesLK: 'dictionary/pagesLK',
            user: 'auth/user',
            searchForm: 'basket/searchForm',
            companies: 'companies/currentCompanies',
            messages: 'notif/messages',
        }),
        key() {
            return this.$route.params.key
        },
        searchOrderForm() {
			return Object.assign({}, this.searchForm)
		},
        linkPath() {
            return this.$route.path
        },
        countNotifications() {
            let count = 0
            this.messages.map((el) => {
                if (!el.is_reader) {
                    count += 1
                }
            })
            return count
        }
    },
    mounted() {
        this.search()
    },
    methods: {
        ...Vuex.mapActions({
            logoutStore: 'auth/logout',
            searchOrder: 'basket/searchOrder',
            searchCompany: 'companies/search',
        }),
        ...Vuex.mapMutations({
            changeSearchForm: 'basket/changeSearchForm',
        }),
        search() {
            this.searchCompany(this.searchForm)
        },
        logout() {
            this.logoutStore()
                .then(() => {
                    this.$router.push('/login')
                })
        },
        pushToActiveOrders() {
            this.searchOrderForm.status = ["Оформлен", "Подтвержден", "В производстве"]
            this.changeSearchForm(this.searchOrderForm)
            this.push('lk/orders')
            if (this.linkPath == '/lk/orders') {
                this.searchOrder()
            }
        },  
        pushToEndedOrders() {
            this.searchOrderForm.status = ["Выполнен", "Готов к отгрузке", "Отгружен"]
            this.changeSearchForm(this.searchOrderForm)
            this.push('lk/orders')
            if (this.linkPath == '/lk/orders') {
                this.searchOrder()
            }
        },
        pushToAllOrders() {
            this.searchOrderForm.status = []
            this.changeSearchForm(this.searchOrderForm)
            this.push('lk/orders')
            if (this.linkPath == '/lk/orders') {
                this.searchOrder()
            }
        },
        showOrderSubsLinks() {
            clearTimeout(this.idTimerOrderSubs)
            this.isShowOrderSubs = true
        },
        closeOrderSubsLink() {
            this.idTimerOrderSubs = setTimeout(() => {
                this.isShowOrderSubs = false
            }, 1000)
        },
    },
}
</script>