<template>
    <div class="lk-profile-page">
        <p class="lk-page_profile-header">Профиль</p>
        <div class="profile_block">
            <p class="profile_block_header">Личные данные</p>
            <p @click="isEdit = true" v-if="!isEdit" class="profile_block_change_link">Изменить</p>
            <p @click="update" v-if="isEdit" class="profile_block_change_link">Сохранить</p>

            <div class="input_block">
                <label for="">Контактное лицо</label>
                <input type="text" placeholder="ФИО" v-model="fio" :disabled="isEdit ? false : true">
            </div>

            <div class="input_block">
                <label for="">Пол</label>
                <div class="checkbox_block">
                    <div class="form-item"  @click="changeGenderUser('Мужской')">
                        <label class="radio radio--primary">
                            <input type="radio" name="type" :checked="(gender == 'Мужской' ? true : false)" data-tab-toggle="1" :disabled="isEdit ? false : true"><span>Мужской</span>
                        </label>
                    </div>
                    <div class="form-item" @click="changeGenderUser('Женский')">
                        <label class="radio radio--primary">
                            <input type="radio" name="type" :checked="(gender == 'Женский' ? true : false)" data-tab-toggle="2" :disabled="isEdit ? false : true"><span>Женский</span>
                        </label>
                    </div>
                </div>
            </div>

            <div class="input_block">
                <label for="">Дата рождения</label>
                <date-picker format="DD.MM.YYYY" v-model="dob" placeholder="Дата рождения" :disabled="isEdit ? false : true"></date-picker>
            </div>

            <div class="input_block">
                <label for="">Электронная почта</label>
                <input type="text" placeholder="Электронная почта" v-model="editedUser.email" :disabled="isEdit ? false : true">
            </div>

            <div class="input_block">
                <label for="">Контактный телефон</label>
                <div class="input_row">
                    <the-mask 
                        v-model="phone" 
                        mask="+7 (###) ###-####" 
                        placeholder="+7 (###) ###-####" 
                        type="tel"
                        :disabled="isEdit ? false : true" 
                        @keyup.native="changePhone"
                    />
                    <button v-if="!isSendedCode && (oldPhone != phone || editedUser.is_check == false)" class="button button--primary" @click="sendConfirmCode">Отправить код</button>
                </div>
                <div v-if="isAcceptedCode" class="accept_code_block">
                    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14.8293 5.39014L16.1693 6.33014L10.3593 14.7101H9.0193L5.7793 10.1701L7.1193 8.92014L9.6893 11.3201L14.8293 5.39014Z" fill="#06BA38"/>
                    </svg>

                    <p>Подтвержден</p>
                </div>
                <div v-if="!isAcceptedCode && oldPhone != phone" class="unconfirmed_code_block">
                    <i class="fas fa-times"></i>

                    <p>Не подтвержден</p>
                </div>
            </div>

            <div v-if="isSendedCode" class="input_block">
                <label for="">Введите код из смс</label>
                <div class="input_row">
                    <input type="text" placeholder="123456" v-model="confirmCode" maxlength="6">
                    <button class="button button--primary" @click="acceptConfirmCode">Подтвердить</button>
                </div>
            </div>

            <div class="input_block">
                <label for="">Город</label>
                <input type="text" placeholder="Город" v-model="city" :disabled="isEdit ? false : true">
            </div>
        </div>
        <div class="profile_block">
            <p class="profile_block_header">Настройки</p>

            <p class="profile_block_setting_link" @click="recovery">Изменить пароль</p>
            <p class="profile_block_setting_link" @click="openDeleteModal">Удалить профиль</p>
        </div>
        <div class="profile_block" style="padding: 20px 40px;">
            <p class="profile_exit_link" @click="logout">Выйти</p>
        </div>

        <delete-modal v-if="isShowDeleteModal" @closeDeleteModal="closeDeleteModal" />
        <error-modal v-if="isShowErrorModal" @closeErrorModal="closeErrorModal" />
    </div>
</template>
<script>
import Vuex from 'vuex'
import Vue from 'vue'
import VueTheMask from 'vue-the-mask'
Vue.use(VueTheMask)
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/ru'
import deleteModal from '@/components/modals/deleteUserModal.vue'
import errorModal from '@/components/modals/errorMessage.vue'
import Functions from '@/utils/functions'

export default {
    mixins: [Functions],
    components: {
        DatePicker,
        deleteModal,
        errorModal,
    },
    data() {
        return {
            isEdit: false,
            gender: 'Мужской',
            fio: null,
            city: null,
            dob: null,
            phone: null,
            isSendedCode: false,
            confirmCode: null,
            isAcceptedCode: false,
            isShowDeleteModal: false,
            oldPhone: null,
            isShowErrorModal: false,
        }
    },
    computed: {
        ...Vuex.mapGetters({
            user: 'auth/user',
            formSmsStore: 'auth/formSms',
        }),
        editedUser() {
            return Object.assign({}, this.user)
        },
        formSms() {
            return Object.assign({}, this.formSmsStore)
        },
    },
    mounted() {
        this.setUserValues()
    },
    methods: {
        ...Vuex.mapActions({
            logoutStore: 'auth/logout',
            updateUser: 'users/updateUser',
            recoveryStore: 'auth/recovery',
            sendVerifySms: 'auth/sms',
            verifyCode: 'auth/verify',
            sendsms: 'auth/sendsms',
            checksms: 'auth/checksms',
        }),
        ...Vuex.mapMutations({
            setShowRecoverySended: 'app/setShowRecoverySended',
            setSendedEmail: 'auth/setSendedEmail',
            setFormSms: 'auth/setFormSms',
            setMessage: 'auth/setMessage',
        }),
        changePhone() {
            this.isAcceptedCode = false
        },
        closeErrorModal() {
            this.isShowErrorModal = false
        },
        logout() {
            this.logoutStore()
                .then(() => {
                    this.$router.push('/login')
                })
        },
        changeGenderUser(type) {
            if (!this.isEdit) {
                return
            }
            this.gender = type
        },
        update() {
            this.editedUser.city = this.city
            this.editedUser.gender = this.gender
            this.editedUser.dob = this.dob
            this.editedUser.phone = this.phone
            if (this.editedUser.phone != this.oldPhone) {
                this.editedUser.is_check = false
            }
            if (this.fio) {
                let splited_fio = this.fio.split(' ')
                if (splited_fio[0]) {
                    this.editedUser.surname = splited_fio[0]
                }
                if (splited_fio[1]) {
                    this.editedUser.name = splited_fio[1]
                }
                if (splited_fio[2]) {
                    this.editedUser.midname = splited_fio[2]
                }
            }   
            this.updateUser(this.editedUser)
                .then(() => {
                    this.isEdit = false
                })
                .catch((error) => {
                    if (error.response.data.error == 'duplicate entry in the table') {
                        this.setMessage('Такой телефон уже существует у другого пользователя')
                    }else{
                        this.setMessage('При обновлении произошла ошибка')
                    }
                    this.isShowErrorModal = true
                })
        },
        setUserValues() {
            if (this.user.gender) {
                this.gender = this.user.gender
            }
            if (this.user.surname || this.user.name || this.user.midname) {
                let fio = ''
                if (this.user.surname) {
                    fio += this.user.surname
                }
                if (this.user.name) {
                    fio += ' ' + this.user.name
                }
                if (this.user.midname) {
                    fio += ' ' + this.user.midname
                }
                this.fio = fio
            }
            if (this.user.city) {
                this.city = this.user.city
            }
            if (this.user.dob) {
                this.dob = new Date(this.user.dob)
            }
            if (this.user.phone) {
                this.phone = this.user.phone
                this.oldPhone = this.user.phone
            }
            if (this.user.is_check == false) {
                this.isAcceptedCode = false
            }else{
                this.isAcceptedCode = true
            }
        },
        sendConfirmCode() {
            this.sendsms({phone: this.getFinallyPhone()})
                .then(() => {
                    this.isSendedCode = true
                    this.isAcceptedCode = false
                })
                .catch(() => {
                    this.isShowErrorModal = true
                })
        },
        acceptConfirmCode() {
            this.formSms.phone = this.getFinallyPhone()
            this.formSms.number_sms = Number(this.confirmCode)
            this.setFormSms(this.formSms)
            
            this.checksms()
                .then(() => {
                    this.confirmCode = null
                    this.isSendedCode = false
                    this.isAcceptedCode = true

                    this.formSms.phone = null
                    this.formSms.number_sms = null
                    this.setFormSms(this.formSms)

                    this.editedUser.is_check = true
                    this.update()
                })
                .catch(() => {
                    this.isShowErrorModal = true
                })
            
        },
        closeDeleteModal() {
            this.isShowDeleteModal = false
        },
        openDeleteModal() {
            this.isShowDeleteModal = true
        },
        recovery() {
            this.setSendedEmail(this.editedUser.email)
            this.recoveryStore({email: this.editedUser.email})
                .then(() => {
                    this.setShowRecoverySended(true)
                })
        },
        getFinallyPhone() {
            var finallyPhone = ''
            var phone = this.phone
            var splitedPhone = phone.split('')
            splitedPhone.map((element) => {
                if (!isNaN(element) && element != ' ') {
                    finallyPhone += element
                }
            })       
            if (finallyPhone.length == 10) {
                finallyPhone = '+7' + finallyPhone
            }else if (finallyPhone.length == 11) {
                finallyPhone = '+' + finallyPhone
            }
            return finallyPhone
        },
    },
}
</script>
<style lang="scss">
    .lk-profile-page{
        .lk-page_profile-header{
            color: black;
            font-weight: 700;
            font-size: 32px;
            line-height: 48px;
            margin-top: 10px;
            margin-bottom: 30px;
        }

        .profile_block{
            padding: 40px;
            margin-bottom: 20px;
            position: relative;
            background-color: #FAFAFA;

            .profile_block_header{
                color: black;
                font-weight: 700;
                font-size: 22px;
                line-height: 33px;
            }

            .profile_block_change_link{
                color: #0071B5;
                font-weight: 500;
                font-size: 22px;
                line-height: 33px;

                position: absolute;
                top: 40px;
                right: 40px;

                &:hover{
                    cursor: pointer;
                }
            }

            .input_block{
                margin-bottom: 40px;
                width: 500px;

                label{
                    color: black;
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 27px;
                    margin-bottom: 8px;
                }
    
                input{
                    border: 1px solid #D6D6D6;
                    border-radius: 10px;
                    color: #353B48;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 22px;
                    padding: 11px 10px;
                    width: 100%;
                }

                .checkbox_block{
                    display: flex;
                    gap: 30px;

                    label{
                        span{
                            color: black;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 24px;
                            padding-left: 24px;

                            &:before{
                                width: 16px;
                                height: 16px;
                                left: 4px;
                                top: 4px;
                            }

                            &:after{
                                width: 10px;
                                height: 10px;
                                left: 7px;
                                top: 7px;
                            }
                        }
                    }
                }

                .mx-datepicker{
                    width: 100%;

                    input{
                        height: 46px;
                    }
                }
                
                .input_row{
                    display: flex;
                    gap: 4px;

                    .button{
                        color: #fff;
                        font-weight: 700;
                        font-size: 16px;
                        line-height: 22px;
                        padding: 11px 19px;
                        border-radius: 10px;
                        width: 50%;
                    }
                }

                .accept_code_block{
                    display: flex;
                    gap: 8px;
                    align-items: center;
                    margin-top: 8px;

                    p{
                        margin-bottom: 0;
                        color: #06BA38;
                        font-weight: 700;
                        font-size: 18px;
                        line-height: 27px;
                    }
                }

                .unconfirmed_code_block{
                    display: flex;
                    gap: 8px;
                    align-items: center;
                    margin-top: 8px;

                    .fas{
                        color: #ED1C24;
                    }

                    p{
                        margin-bottom: 0;
                        color: #ED1C24;
                        font-weight: 700;
                        font-size: 18px;
                        line-height: 27px;
                    }
                }
            }


            .profile_block_setting_link{
                color: #718093;
                font-weight: 400;
                font-size: 22px;
                line-height: 33px;

                &:hover{
                    cursor: pointer;
                }
            }

            .profile_exit_link{
                color: #ED1C24;
                font-weight: 700;
                font-size: 22px;
                line-height: 33px;

                &:hover{
                    cursor: pointer;
                }
            }
        }
    }
</style>