<template>
    <div class="lk-page_companies">
        <div class="lk-page_companies-header_buttons">
            <button @click="push('lk/companies')" class="button button--default back_button">
                <svg width="21" height="16" viewBox="0 0 21 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.292892 8.70711C-0.0976315 8.31658 -0.0976315 7.68342 0.292892 7.29289L6.65685 0.928932C7.04738 0.538408 7.68054 0.538408 8.07107 0.928932C8.46159 1.31946 8.46159 1.95262 8.07107 2.34315L2.41421 8L8.07107 13.6569C8.46159 14.0474 8.46159 14.6805 8.07107 15.0711C7.68054 15.4616 7.04738 15.4616 6.65685 15.0711L0.292892 8.70711ZM21 9H1V7H21V9Z" fill="black"/>
                </svg>

                Назад
            </button>

            <button v-if="currentCompanyAccess == 50 || can('admin')" @click="openAddModal" class="button button--primary add_button">
                + Добавить сотрудника
            </button>
        </div>
        <p class="lk-page_companies-header">Сотрудники</p>
        <p class="lk-page_companies-subheader">{{ currentCompany.name }}</p>
        <div class="lk-page_companies-filters">
            <div class="lk-page_companies-filters_ones" style="margin-right: 10px; width: 83%;">
                <input 
                    type="text" 
                    class="filters_search_input" 
                    placeholder="Поиск" 
                    v-model="searchForm.query" 
                    @change="search" 
                    @keydown.enter="search" 
                    @paste="pasteQuery"
                >
                <svg class="svg_search" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.96907 9.96387L14 14M11.3333 6.66667C11.3333 9.244 9.244 11.3333 6.66667 11.3333C4.08934 11.3333 2 9.244 2 6.66667C2 4.08934 4.08934 2 6.66667 2C9.244 2 11.3333 4.08934 11.3333 6.66667Z" stroke="#718093" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>
            <div class="lk-page_companies-filters_ones" style="width: 16%;">
                <!-- <input type="text" placeholder="Тип доступа" v-model="searchForm.type" @keydown.enter="search" > -->

                <div class="custom_select">
                    <div @click="openedCustomSelect = !openedCustomSelect" class="choosen_item">
                        <p>{{ searchForm.access ? getNameAccess(searchForm.access ) : 'Тип доступа' }}</p>

                        <svg v-if="!openedCustomSelect" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.29289 8.29289C5.68342 7.90237 6.31658 7.90237 6.70711 8.29289L12 13.5858L17.2929 8.29289C17.6834 7.90237 18.3166 7.90237 18.7071 8.29289C19.0976 8.68342 19.0976 9.31658 18.7071 9.70711L12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071L5.29289 9.70711C4.90237 9.31658 4.90237 8.68342 5.29289 8.29289Z" fill="#353B48"/>
                        </svg>

                        <svg v-if="openedCustomSelect" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M18.7071 15.7071C18.3166 16.0976 17.6834 16.0976 17.2929 15.7071L12 10.4142L6.70711 15.7071C6.31658 16.0976 5.68342 16.0976 5.29289 15.7071C4.90237 15.3166 4.90237 14.6834 5.29289 14.2929L11.2929 8.29289C11.6834 7.90237 12.3166 7.90237 12.7071 8.29289L18.7071 14.2929C19.0976 14.6834 19.0976 15.3166 18.7071 15.7071Z" fill="#353B48"/>
                        </svg>
                    </div>
                    <div v-if="openedCustomSelect" @mouseleave="openedCustomSelect = false" class="items">
                        <p @click="setFormAccess(null)">Тип доступа</p>
                        <p
                            v-for="opt, idx in companyTypes"
                            :key="idx"
                            @click="setFormAccess(opt.id)"
                        >
                            {{ opt.name }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="lk-page_companies-button-block">
            <div class="lk-page_companies-button-block_ones" :class="numActiveFilter == 1 ? 'lk-page_companies-button-block_ones-active' : ''" @click="changeActiveFilter(1)">Текущие</div>
            <div class="lk-page_companies-button-block_ones" :class="numActiveFilter == 2 ? 'lk-page_companies-button-block_ones-active' : ''" @click="changeActiveFilter(2)">Ожидают подтверждения {{ countWaitUsers }}</div>
            <div class="lk-page_companies-button-block_ones" :class="numActiveFilter == 3 ? 'lk-page_companies-button-block_ones-active' : ''" @click="changeActiveFilter(3)">Архив</div>
        </div>
        <div class="lk-page_companies-employees-table">
            <div class="lk-page_companies-employees-table_headers">
                <div class="lk-page_companies-employees-table_headers_once" :style="numActiveFilter == 1 ? 'width: 25%;' : 'width: 40%;'">
                    <p>ФИО</p>
                </div>
                <div class="lk-page_companies-employees-table_headers_once" :style="numActiveFilter == 1 ? 'width: 20%;' : 'width: 40%;'">
                    <p>Email</p>
                </div>
                <div v-if="numActiveFilter == 1" class="lk-page_companies-employees-table_headers_once" style="width: 15%;">
                    <p>Дата создания</p>
                </div>
                <div v-if="numActiveFilter == 1" class="lk-page_companies-employees-table_headers_once" style="width: 15%;">
                    <p>Дата добавления</p>
                </div>
                <div v-if="numActiveFilter == 1" class="lk-page_companies-employees-table_headers_once" style="width: 20%;">
                    <p>Тип доступа</p>
                </div>
                <div class="lk-page_companies-employees-table_headers_once" :style="numActiveFilter == 1 ? 'width: 5%;' : 'width: 20%;'">
                </div>
            </div>
            <single 
                v-for="user in usersCompany.filter(filterUsers)" 
                :key="user.id"
                :element="user" 
                :activeFilter="numActiveFilter"
                :currentCompanyAccess="currentCompanyAccess"
                @openDeleteModal="openDeleteModal" 
                @openAccessModal="openAccessModal" 
                @searchUsersDefault="searchUsersDefault"
            />
        </div>

        <delete-modal v-if="isShowDeleteModal" @closeDeleteModal="closeDeleteModal" />
        <add-modal v-if="isShowAddModal" @closeAddModal="closeAddModal" />
        <access-modal v-if="isShowAccessModal" @closeAccessModal="closeAccessModal" />
    </div>
</template>
<script>
import Vuex from 'vuex'
import Functions from '@/utils/functions'
import Acl from '@/utils/acl'
import single from './singleEmployee.vue'
import deleteModal from './modals/deleteEmployee.vue'
import addModal from './modals/addEmployee.vue'
import accessModal from './modals/changeAccess.vue'

export default {
    mixins: [Functions, Acl],
    components: {
        single,
        deleteModal,
        addModal,
        accessModal,
    },
    data() {
        return {
            searchForm: {
                query: null,
                access: null,
            }, 
            numActiveFilter: 1,
            isShowEventBlock: false,
            isShowDeleteModal: false,
            isShowAddModal: false,
            isShowAccessModal: false,
            openedCustomSelect: false,
        }
    },
    computed: {
        ...Vuex.mapGetters({
            currentCompany: 'companies/currentCompany',
            usersCompany: 'companies/usersCompany',
            companyTypes: 'dictionary/companyTypes',
            user: 'auth/user',
        }),
        companyId() {
            return this.$route.params.id
        },
        countWaitUsers() {
            let count = 0
            this.usersCompany.filter(this.filterUsers).map((el) => {
                if (el.access == 70 || el.access == 10 || el.access == 20) {
                    count += 1
                }
            })
            if (count == 0) {
                count = null
            }
            return count
        },
        currentCompanyAccess() {
            let access = null
            this.user.user_link_company.map((el) => {
                if (el.company_id == this.currentCompany.id) {
                    access = el.access
                }
            })
            return access
        },
    },
    mounted() {
        this.getUsers()
        this.getOne(Number(this.companyId))
            .then(() => {
                this.searchUsersDefault()
            })
    },
    methods: {
        ...Vuex.mapActions({
            getOne: 'companies/getOne',
            searchUsers: 'companies/searchUsers',
            getUsers: 'users/getUsers',
        }),
        ...Vuex.mapMutations({
            setDeleteEmployeeId: 'companies/setDeleteEmployeeId',
            setLinkObject: 'companies/setLinkObject',
        }),
        filterUsers(el) {
            let fio = el.surname + ' ' + el.name + ' ' + el.midname
            if ((this.searchForm.query && !fio.includes(this.searchForm.query)) || (this.searchForm.access && el.access != this.searchForm.access)) {
                return false
            }else{
                return true
            }
        },
        setFormAccess(val) {
            this.searchForm.access = val
            this.openedCustomSelect = false
            if (val == 60) {
                this.numActiveFilter = 3
            } else if (val == 0 || val == 10 || val == 20) {
                this.numActiveFilter = 2
            }else{
                this.numActiveFilter = 1
            }
        },
        getNameAccess(val) {
            let name = null
            this.companyTypes.map((el) => {
                if (el.id == val) {
                    name = el.name
                }
            })
            return name
        },
        search() {
            let access = this.searchForm.access ? Number(this.searchForm.access) : null
            this.searchUsers({
                name: this.searchForm.query,
                access: access,
                id: Number(this.companyId),
            })
        },
        searchUsersDefault() {
            this.searchUsers({id: Number(this.companyId)})
        },
        changeActiveFilter(num) {
            this.numActiveFilter = num
        },
        openDeleteModal() {
            this.isShowDeleteModal = true
        },
        closeDeleteModal() {
            this.setDeleteEmployeeId(null)
            this.searchUsersDefault()
            this.isShowDeleteModal = false
        },
        openAddModal() {
            this.isShowAddModal = true
        },
        closeAddModal() {
            this.isShowAddModal = false
        },
        openAccessModal() {
            this.isShowAccessModal = true
        },
        closeAccessModal() {
            this.setLinkObject({})
            this.searchUsersDefault()
            this.isShowAccessModal = false
        },
        pasteQuery() {

        },
        getAllOrders(){

        },
    },
}
</script>