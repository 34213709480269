import Http from "../utils/http"
import Config from "../config"
import store from './index'

const state = {
    message: null,
    orders: [],

    searchForm: {
        date_before: null,
        date_create: null,
        excludedIds: [],
        ids: [],
        // limit: 10,
        order_id_1c: null,
        status: [],
        page: 1,
        query: null,
        company_id1cs: [],
        sumFrom: null,
        sumTo: null,
    },

    order: {
        id: null,
        positions: [],
        
        account_type: null,
        location: null,
        postcode: null,

        delivery_type: null,
        pay_type: null,

        organization_name: null,
        organization_address: null,
        inn: null,
        kpp: null,

        company_id: null,

        fio: null,
        email:null,
        phone: null,
        comment: null,
    },
    newPosition: {
        id_1c: null,
        name: null,
        coating: '',
        color: null,
        color_external: null,
        color_inner: null,
        external_cladding_thickness: null,
        geometry: null,
        insulation_thickness: null,
        internal_knurling: null,
        internal_thickness: null,
        internal_lining_thickness: null,
        internal_surface_coating: null,
        knurling_on_the_outer_surface: null,
        length: null,
        maximum_length: null,
        metal_thickness: null,
        minimum_length: null,
        mounting_width: null,
        overall_width: null,
        price_group: null,
        price_unit: null,
        price: null,
        profile: null,
        rolling_side: null,
        rolling_the_inner_lining: null,
        rolling_the_outer_cladding: null,
        texture: null,
        thickness: null,
        type_item: null,
        wave_height: null,
        wave_length: null,
        width: null,
        count_base_unit_of_measure: 1,
        base_unit_of_measure: null,
        amount: 1,
        sell: 0,
        amount_in_progress: null,
        amount_in_stock: null,
        amount_shipped: null,
        amount_to_work: null,
        amount_balance: null,
        root_id: null,
        diameter: null,
        head_type: null,
        shelf_a: null,
        shelf_b: null,
        tip_type: null,
    },
    isOrderReady: false,

    finishedOrder: {
        pay_type: null,
        sum: 0,
    },

    isCanSearchOrder: true,
    isSearchOrdersEnded: true,

    isShowConfirmOrderModal: false,
    isShowDeclineOrderModal: false,
    isTypeConfirmOrder: 20,
    countStatuses: {},
    maxSellOrder: 0,

    currentOrder: {},
    isShowLastConfirmOrder: false,
}

const getters = {
    message: state => state.message,
    order: state => state.order,
    newPosition: state => state.newPosition,
    isOrderReady: state => state.isOrderReady,
    orders: state => state.orders,
    searchForm: state => state.searchForm,
    finishedOrder: state => state.finishedOrder,
    isCanSearchOrder: state => state.isCanSearchOrder,
    isSearchOrdersEnded: state => state.isSearchOrdersEnded,

    isShowConfirmOrderModal: state => state.isShowConfirmOrderModal,
    isShowDeclineOrderModal: state => state.isShowDeclineOrderModal,
    isTypeConfirmOrder: state => state.isTypeConfirmOrder,
    countStatuses: state => state.countStatuses,
    maxSellOrder: state => state.maxSellOrder,

    currentOrder: state => state.currentOrder,
    isShowLastConfirmOrder: state => state.isShowLastConfirmOrder,
}

const actions = {
    get: ({commit}, id) => {
        let cid = (store.getters['auth/userCompanyId'] ? store.getters['auth/userCompanyId'] : store.getters['auth/localStorageCompanyId'])
        return new Promise((resolve) => {
            Http.get(Config.apiUrl + "/v1/order/" + id, {headers: {'X-Company-ID': cid}})
                .then(response => {
                    commit("setOrder", response.data)
                    resolve(response)
                })
        })
    },
    create: ({commit}, object) => {
        let id = (store.getters['auth/userCompanyId'] ? store.getters['auth/userCompanyId'] : store.getters['auth/localStorageCompanyId'])
        return new Promise((resolve, reject) => {
            Http.post(Config.apiUrl + "/v1/order/create", object, {headers: {'X-Company-ID': id}})
                .then(response => {
                    commit("setOrder", response.data)
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    addPosition: ({commit}, object) => {
        let id = (store.getters['auth/userCompanyId'] ? store.getters['auth/userCompanyId'] : store.getters['auth/localStorageCompanyId'])
        return new Promise((resolve) => {
            Http.post(Config.apiUrl + "/v1/order/position/add/" + object.order_id, object, {headers: {'X-Company-ID': id}})
                .then(response => {
                    commit("pushToOrder", response.data)
                    resolve(response)
                })
        })
    },
    deletePosition: ({commit}, id) => {
        let cid = (store.getters['auth/userCompanyId'] ? store.getters['auth/userCompanyId'] : store.getters['auth/localStorageCompanyId'])
        return new Promise((resolve) => {
            Http.delete(Config.apiUrl + "/v1/order/position/" + id, {headers: {'X-Company-ID': cid}})
                .then(response => {
                    commit("deleteProduct", id)
                    resolve(response)
                })
        })
    },
    updatePosition: ({commit}, payload) => {
        let id = (store.getters['auth/userCompanyId'] ? store.getters['auth/userCompanyId'] : store.getters['auth/localStorageCompanyId'])
        return new Promise((resolve) => {
            Http.put(Config.apiUrl + "/v1/order/position/" + payload.position.id, payload.position, {headers: {'X-Company-ID': id}})
                .then(response => {
                    commit("replacePosition", {position: response.data, root_id: payload.root_id})
                    resolve(response)
                })
        })
    },
    orderRegistration: ({commit, getters}) => {
        let id = (store.getters['auth/userCompanyId'] ? store.getters['auth/userCompanyId'] : store.getters['auth/localStorageCompanyId'])
        return new Promise((resolve) => {
            Http.get(Config.apiUrl + "/v1/order/registration/" + getters.order.id, {headers: {'X-Company-ID': id}})
                .then(response => {
                    commit("setMessage", 'success')
                    resolve(response)
                })
        })
    },
    updateOrder: ({commit}, order) => {
        let id = (store.getters['auth/userCompanyId'] ? store.getters['auth/userCompanyId'] : store.getters['auth/localStorageCompanyId'])
        return new Promise((resolve) => {
            Http.put(Config.apiUrl + "/v1/order/" + order.id, order, {headers: {'X-Company-ID': id}})
                .then(response => {
                    commit("changeOrder", response.data)
                    resolve(response)
                })
        })
    },
    deleteOrder: ({commit}, id) => {
        let cid = (store.getters['auth/userCompanyId'] ? store.getters['auth/userCompanyId'] : store.getters['auth/localStorageCompanyId'])
        return new Promise((resolve, reject) => {
            Http.delete(Config.apiUrl + "/v1/order/" + id, {headers: {'X-Company-ID': cid}})
                .then(response => {
                    commit("deleteOrderFromArray", id)
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    searchOrder: ({commit, getters}) => {
        let id = (store.getters['auth/userCompanyId'] ? store.getters['auth/userCompanyId'] : store.getters['auth/localStorageCompanyId'])
        return new Promise((resolve) => {
            Http.post(Config.apiUrl + "/v1/order/search", getters.searchForm, {headers: {'X-Company-ID': id}})
                .then(response => {
                    commit("setOrders", response.data)
                    resolve(response)
                })
        })
    },
    searchNextPageOrder: ({commit, getters}) => {
        let id = (store.getters['auth/userCompanyId'] ? store.getters['auth/userCompanyId'] : store.getters['auth/localStorageCompanyId'])
        if (!getters.isCanSearchOrder || !getters.isSearchOrdersEnded) {
            return
        }
        commit('setSearchOrdersEnded', false)
        return new Promise((resolve) => {
            Http.post(Config.apiUrl + "/v1/order/search", getters.searchForm, {headers: {'X-Company-ID': id}})
                .then(response => {
                    commit('setSearchOrdersEnded', true)
                    commit("addNextPageOrders", response.data)
                    resolve(response)
                })
        })
    },
    calculate: ({commit, getters}) => {
        let id = (store.getters['auth/userCompanyId'] ? store.getters['auth/userCompanyId'] : store.getters['auth/localStorageCompanyId'])
        return new Promise((resolve) => {
            Http.post(Config.apiUrl + "/v1/order/position/calculate", [getters.newPosition], {headers: {'X-Company-ID': id}})
                .then(response => {
                    commit("setNewPosition", response.data)
                    resolve(response)
                })
        })
    },
    copyOrder: ({commit}, payload) => {
        let id = (store.getters['auth/userCompanyId'] ? store.getters['auth/userCompanyId'] : store.getters['auth/localStorageCompanyId'])
        return new Promise((resolve, reject) => {
            Http.post(Config.apiUrl + "/v1/order/" + payload.id + "/copy", { company_id: payload.company_id }, {headers: {'X-Company-ID': id}})
                .then(response => {
                    commit("setOrder", response.data)
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    getCountStatuses: ({commit}) => {
        return new Promise((resolve) => {
            Http.post(Config.apiUrl + "/v1/order/search/status_all", {})
                .then(response => {
                    commit("setCountStatuses", response.data)
                    resolve(response)
                })
        })
    },
    getMaxSell: ({commit}) => {
        return new Promise((resolve) => {
            Http.get(Config.apiUrl + "/v1/order/max_sell")
                .then(response => {
                    commit("setMaxSellOrder", response.data)
                    resolve(response)
                })
        })
    },
    getOne: ({commit}, id) => {
        let cid = (store.getters['auth/userCompanyId'] ? store.getters['auth/userCompanyId'] : store.getters['auth/localStorageCompanyId'])
        return new Promise((resolve) => {
            Http.post(Config.apiUrl + "/v1/order/search", {ids: [id]} , {headers: {'X-Company-ID': cid}})
                .then(response => {
                    commit("setCurrentOrder", response.data)
                    resolve(response)
                })
        })
    },
    sendSms: ({commit}, payload) => {
        return new Promise((resolve) => {
            Http.post(Config.apiUrl + "/v1/order/send_sms", payload)
                .then(response => {
                    commit("setMessage", 'message sended')
                    resolve(response)
                })
        })
    },
    sendEmail: ({commit}, payload) => {
        return new Promise((resolve) => {
            Http.post(Config.apiUrl + "/v1/order/send_email", payload)
                .then(response => {
                    commit("setMessage", 'email sended')
                    resolve(response)
                })
        })
    },
    confirmWithCode: ({commit}, payload) => {
        return new Promise((resolve) => {
            Http.post(Config.apiUrl + "/v1/order/change_status", payload)
                .then(response => {
                    commit("setMessage", 'order confirmed')
                    resolve(response)
                })
        })
    },
    cancelOrder: ({commit}, payload) => {
        let id = (store.getters['auth/userCompanyId'] ? store.getters['auth/userCompanyId'] : store.getters['auth/localStorageCompanyId'])
        return new Promise((resolve) => {
            Http.post(Config.apiUrl + "/v1/order/cancel_status", payload, {headers: {'X-Company-ID': id}})
                .then(response => {
                    commit("changeOrder", response.data)
                    resolve(response)
                })
        })
    },
}


const mutations = {
    setShowLastConfirmOrder: (state, val) => {
        state.isShowLastConfirmOrder = val
    },
    setCurrentOrder: (state, val) => {
        if (Array.isArray(val)) {
            val = val[0]
        }
        state.currentOrder = val
    },
    setSearchOrdersEnded: (state, val) => {
        state.isSearchOrdersEnded = val
    },
    setFinishedOrder: (state, val) => {
        state.finishedOrder = val
    },
    setMessage: (state, val) => {
        state.message = val
    },
    setBasket: (state, val) => {
        state.basket = val
    },
    setProducts: (state, val) => {
        state.order.positions = val
    },
    addProduct: (state, obj) => {
        state.basket.products.push(obj)
    },
    deleteProduct: (state, id) => {
        state.order.positions.map((element, i) => {
            if (id == element.id) {
                state.order.positions.splice(i, 1)
            }
        })
    },
    setOrder: (state, val) => {
        if (Array.isArray(val)) {
            val = val[0]
        }

        if (val.positions == null) {
            val.positions = []
        }
        state.order = val
        state.isOrderReady = true
    },
    pushToOrder: (state, obj) => {
        state.order.positions.push(obj)
    },
    changeNewPosition: (state, obj) => {
        state.newPosition[obj.type] = obj.value
    },
    setNewPosition: (state, val) => {
        state.newPosition.sell = val.Positions[0].sell
        state.newPosition.count_base_unit_of_measure = val.Positions[0].count_base_unit_of_measure
    },
    changeOrder:(state, val) => {
        state.order = val
    },
    replacePosition: (state, object) => {
        state.order.positions.map((element) => {
            if (element.id == object.position.id) {
                element.amount = object.position.amount
                element.sell = object.position.sell
                element.root_id = object.root_id
            }
        })
    },
    clearNewPosition: (state, id_1c) => {
        state.newPosition.id_1c = id_1c
        state.newPosition.length = null
        state.newPosition.width = null
    },
    setOrders: (state, val) => {
        if (val == null) {
            val = []
        }
        state.orders = val
    },
    setCanSearchOrder:(state, val) => {
        state.isCanSearchOrder = val
    },
    addNextPageOrders: (state, val) => {
        if (val == null || val.length == 0) {
            state.isCanSearchOrder = false
            return
        }
        let currentValue = state.orders
        let newValue = currentValue.concat(val)
        state.orders = newValue
        
    },
    changeSearchForm: (state, val) => {
        state.searchForm = val
    },
    deleteOrderFromArray: (state, id) => {
        state.orders.map((element, i) => {
            if (element.id == id) {
                state.orders.splice(i, 1)
            }
        })
    },
    pushStatusForm: (state, val) => {
        state.searchForm.status.push(val)
    },
    spliceStatusForm: (state, index) => {
        state.searchForm.status.splice(index, 1)
    },
    pushCompanyForm: (state, val) => {
        state.searchForm.company_id1cs.push(val)
    },
    spliceCompanyForm: (state, index) => {
        state.searchForm.company_id1cs.splice(index, 1)
    },
    clearStatusForm: (state) => {
        state.searchForm.status = []
    },
    setShowConfirmOrderModal: (state, val) => {
        state.isShowConfirmOrderModal = val
    },
    setShowDeclineOrderModal: (state, val) => {
        state.isShowDeclineOrderModal = val
    },
    setTypeConfirmOrder: (state, val) => {
        state.isTypeConfirmOrder = val
    },
    setCountStatuses: (state, val) => {
        state.countStatuses = val
    },
    setMaxSellOrder: (state, val) => {
        state.maxSellOrder = val
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
