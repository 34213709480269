<template>
    <div v-if="(activeFilter == 1 && ((typeAccess > 20 && typeAccess < 60) || typeAccess == 0)) || (activeFilter == 2 && (typeAccess == 10 || typeAccess == 20 || typeAccess == 70)) || (activeFilter == 3 && typeAccess == 60)" class="lk-page_companies-list_ones">
        <div class="lk-page_companies-list_ones-header_row">
            <div class="left_part">{{ element.id_1c }}</div>
            <div class="right_part" style="min-height: 24px;">
                <svg v-if="typeAccess == 40 || typeAccess == 50 || can('admin')" @click="isShowEventBlock = !isShowEventBlock" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.6875 5.41406C10.6875 5.76216 10.8258 6.096 11.0719 6.34214C11.3181 6.58828 11.6519 6.72656 12 6.72656C12.3481 6.72656 12.6819 6.58828 12.9281 6.34214C13.1742 6.096 13.3125 5.76216 13.3125 5.41406C13.3125 5.06597 13.1742 4.73213 12.9281 4.48598C12.6819 4.23984 12.3481 4.10156 12 4.10156C11.6519 4.10156 11.3181 4.23984 11.0719 4.48598C10.8258 4.73213 10.6875 5.06597 10.6875 5.41406ZM10.6875 11.9766C10.6875 12.3247 10.8258 12.6585 11.0719 12.9046C11.3181 13.1508 11.6519 13.2891 12 13.2891C12.3481 13.2891 12.6819 13.1508 12.9281 12.9046C13.1742 12.6585 13.3125 12.3247 13.3125 11.9766C13.3125 11.6285 13.1742 11.2946 12.9281 11.0485C12.6819 10.8023 12.3481 10.6641 12 10.6641C11.6519 10.6641 11.3181 10.8023 11.0719 11.0485C10.8258 11.2946 10.6875 11.6285 10.6875 11.9766ZM10.6875 18.5391C10.6875 18.8872 10.8258 19.221 11.0719 19.4671C11.3181 19.7133 11.6519 19.8516 12 19.8516C12.3481 19.8516 12.6819 19.7133 12.9281 19.4671C13.1742 19.221 13.3125 18.8872 13.3125 18.5391C13.3125 18.191 13.1742 17.8571 12.9281 17.611C12.6819 17.3648 12.3481 17.2266 12 17.2266C11.6519 17.2266 11.3181 17.3648 11.0719 17.611C10.8258 17.8571 10.6875 18.191 10.6875 18.5391Z" fill="black"/>
                </svg>

                <div v-if="isShowEventBlock" class="company_event_list">
                    <div v-if="typeAccess == 50 || can('admin')" @click="openDeleteModal(element.id)" class="company_event_list_ones">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7 21C6.45 21 5.97933 20.8043 5.588 20.413C5.19667 20.0217 5.00067 19.5507 5 19V6H4V4H9V3H15V4H20V6H19V19C19 19.55 18.8043 20.021 18.413 20.413C18.0217 20.805 17.5507 21.0007 17 21H7ZM17 6H7V19H17V6ZM9 17H11V8H9V17ZM13 17H15V8H13V17Z" fill="black"/>
                        </svg>
                        <p>Удалить организацию</p>
                    </div>
                    <div v-if="typeAccess == 40 || typeAccess == 50 || can('admin')" @click="push('lk/employees/' + element.id)" class="company_event_list_ones">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4 17.875C4 16.7478 4.44777 15.6668 5.2448 14.8698C6.04183 14.0728 7.12283 13.625 8.25 13.625H16.75C17.8772 13.625 18.9582 14.0728 19.7552 14.8698C20.5522 15.6668 21 16.7478 21 17.875C21 18.4386 20.7761 18.9791 20.3776 19.3776C19.9791 19.7761 19.4386 20 18.875 20H6.125C5.56141 20 5.02091 19.7761 4.6224 19.3776C4.22388 18.9791 4 18.4386 4 17.875Z" stroke="#050505" stroke-width="2" stroke-linejoin="round"/>
                            <path d="M12.5 9.37503C14.2604 9.37503 15.6875 7.94793 15.6875 6.18751C15.6875 4.4271 14.2604 3 12.5 3C10.7396 3 9.3125 4.4271 9.3125 6.18751C9.3125 7.94793 10.7396 9.37503 12.5 9.37503Z" stroke="#050505" stroke-width="2"/>
                        </svg>
                        <p>Сотрудники</p>
                    </div>
                    <div v-if="typeAccess == 50 || can('admin')" @click="openEditModal(element)" class="company_event_list_ones">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.06 9.02L14.98 9.94L5.92 19H5V18.08L14.06 9.02ZM17.66 3C17.41 3 17.15 3.1 16.96 3.29L15.13 5.12L18.88 8.87L20.71 7.04C20.8027 6.94749 20.8762 6.8376 20.9264 6.71662C20.9766 6.59565 21.0024 6.46597 21.0024 6.335C21.0024 6.20403 20.9766 6.07435 20.9264 5.95338C20.8762 5.8324 20.8027 5.72251 20.71 5.63L18.37 3.29C18.17 3.09 17.92 3 17.66 3ZM14.06 6.19L3 17.25V21H6.75L17.81 9.94L14.06 6.19Z" fill="black"/>
                        </svg>
                        <p>Редактировать реквизиты</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="lk-page_companies-list_ones-main_row">
            <div class="left_part">
                <p class="company_name">{{ element.name }}</p>
                <p class="company_info">{{ element.email }}</p>
                <p class="company_info">{{ element.phone }}</p>
            </div>
            <div class="mid_part">
                <div class="mid_part_row">
                    <p class="mid_part_row_name">ИНН</p>
                    <p class="mid_part_row_value">{{ element.inn }}</p>
                </div>
                <div class="mid_part_row">
                    <p class="mid_part_row_name">КПП</p>
                    <p class="mid_part_row_value">{{ element.kpp }}</p>
                </div>
            </div>
            <div class="right_part">
                <div class="button_block" v-if="typeAccess > 20 && typeAccess < 60">
                    <p class="role_name">Роль: {{ typeAccess == 50 ? 'Администратор' : 'Сотрудник' }}</p>
                    <button class="button button--primary">Доступ получен</button>
                </div>
                <div class="button_block" v-else>
                    <button class="button button--primary button-black" @click="openRequestModal">Запросить доступ</button>
                </div>
            </div>
            <!-- // {{ typeAccess }} -->
        </div>
    </div>
</template>
<script>
import Functions from '@/utils/functions'
import Acl from '@/utils/acl'
import Vuex from 'vuex'

export default {
    mixins: [Functions, Acl],
    props: {
        element: {
            type: Object,
            default: () => {}
        },
        activeFilter: {
            type: Number,
            default: 1
        },
    },
    data() {
        return {
            isShowEventBlock: false,
            is_access: false,
            editElement: {},
        }
    },
    computed: {
        ...Vuex.mapGetters({
            user: 'auth/user',
        }),
        typeAccess() {
            let access = 0
            let companies = this.user.user_link_company
            companies.map((element) => {
                if (element.company_id == this.element.id) {
                    access = element.access
                }
            })
            return access
        },
    },
    methods: {
        ...Vuex.mapMutations({
            setDeleteCompanyId: 'companies/setDeleteCompanyId',
            setEditCompanyElement: 'companies/setEditCompanyElement',
        }),
        openEditModal(element) {
            this.setEditCompanyElement(element)
            this.$emit('openEditModal')
        },
        openDeleteModal(id) {
            this.setDeleteCompanyId(id)
            this.$emit('openDeleteModal')
        },
        openRequestModal() {
            this.$emit('openRequestModal')
        },
    },
}
</script>