<template>
    <div class="modal-iframe document-act-modal" @click="checkEvent">
        <div class="modal modal--form modal--opened modal--visible" id="modal_callback">
            <div class="modal-container">
                <div class="state">
                    <p class="header_modal">Акт сверки</p>
                    <p v-if="isSended" class="message_modal">Ваш запрос отправлен в обработку, сообщим Вам о готовности</p>
                    <div v-if="!isSended" class="custom_select_modal">
                        <label for="">Выберите компанию</label>
                        <div class="custom_select">
                            <div 
                                class="select_button"
                                @click="isShowSelectList = !isShowSelectList"
                            >
                                <p>{{ selectedCompanyName ? selectedCompanyName : 'Выберите компанию' }}</p>
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.52827 5.52876C3.78862 5.26841 4.21073 5.26841 4.47108 5.52876L7.99967 9.05735L11.5283 5.52876C11.7886 5.26841 12.2107 5.26841 12.4711 5.52876C12.7314 5.78911 12.7314 6.21122 12.4711 6.47157L8.47108 10.4716C8.21073 10.7319 7.78862 10.7319 7.52827 10.4716L3.52827 6.47157C3.26792 6.21122 3.26792 5.78911 3.52827 5.52876Z" fill="#050505"/>
                                </svg>
                            </div>
                            <div v-if="isShowSelectList" class="select_list">
                                <div 
                                    class="select_once"
                                    v-for="companyOne in companies"
                                    :key="companyOne.id"    
                                >
                                    <p @click="selectCompany(companyOne.name)">{{ companyOne.name }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button v-if="!isSended" class="button button--primary" @click="send">Запросить</button>
                    <button v-if="isSended" class="button button--primary" @click="close">Ок</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Vuex from 'vuex'
import Functions from '@/utils/functions'
import Acl from '@/utils/acl'

export default {
    mixins: [Functions, Acl],
    data() {
        return {
            isSended: false,
            isShowSelectList: false,
            selectedCompanyName: '',
            startDate: "2022-01-02",
			endDate: "2024-01-02",
        }
    },
    computed: {
        ...Vuex.mapGetters({
            user: 'auth/user',
            formCompanies: 'companies/form',
            companies: 'companies/companies',
            formAct: 'reports/formAct',
        }),
        searchCompaniesForm() {
			return Object.assign({}, this.formCompanies)
		},
        searchForm() {
            return Object.assign({}, this.formAct)
        },
    },
    mounted() {
        this.getCompaniesList()
    },
    methods: {
        ...Vuex.mapMutations({
            setShowUserExistModal: 'app/setShowUserExistModal',
            updateCompaniesForm: 'companies/updateForm',
            setActForm: 'reports/setActForm',
        }),
        ...Vuex.mapActions({
            getCompanies: 'companies/getCompanies',
            getAct: 'reports/getAct',
        }),
        close() {
            this.selectedCompanyName = ''
            this.update()
            this.$emit('closeModal')
        },
        forget() {
            this.close()
            this.push('recovery')
        },
        selectCompany(company) {
            this.selectedCompanyName = company
            this.isShowSelectList = false
            this.update()
        },
        getCompaniesList() {
            if (!this.can('admin')) {
                let user_companies = this.user.user_link_company
                let array = []
                user_companies.map((element) => {
                    if(element.is_admin == true) {
                        array.push(element.company_id)
                    }
                })

                this.searchCompaniesForm.ids = array
                this.updateCompaniesForm(this.searchCompaniesForm)
            }else{
                this.searchCompaniesForm.ids = []
                this.updateCompaniesForm(this.searchCompaniesForm)
            }
            this.getCompanies()
        },
        update() {
            if (this.startDate) {
				let today = new Date(this.startDate)
				this.searchForm.date_start = today.toISOString()
			}else{
				this.searchForm.date_start = null
			}

			if (this.endDate) {
				let today = new Date()
				this.searchForm.date_end = today.toISOString()
			}else{
				this.searchForm.date_end = null
			}
            this.searchForm.client = this.selectedCompanyName
            this.setActForm(this.searchForm)
        },
        send() {
            this.getAct()
                .then(() => {
                    this.isSended = true
                })
        },
        checkEvent(event) {
            let target = event.target.className 
            if (target == 'modal modal--form modal--opened modal--visible') {
                this.close()
            }
        }
    }
}
</script>
<style lang="scss">
    .document-act-modal{
        .modal-container{
            padding: 24px !important;
            max-width: 395px !important;
            border-radius: 5px;

            .header_modal{
                color: black;
                font-weight: 700;
                font-size: 22px;
                line-height: 33px;
                margin-bottom: 13px;
            }

            .message_modal{
                color: black;
                font-weight: 400;
                font-size: 14px;
                line-height: 21px;
                margin-bottom: 13px;
            }

            .custom_select_modal{
                margin-bottom: 13px;

                label{
                    color: black;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 21px;
                    margin-bottom: 13px;
                }

                .custom_select{
                    position: relative;

                    .select_button{
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        border: 1px solid #D6D6D6;
                        border-radius: 10px;
                        color: #718093;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 22px;
                        padding: 11px 10px;
                        
                        &:hover{
                            cursor: pointer;
                        }

                        p{
                            margin-bottom: 0;
                        }
                    }

                    .select_list{
                        position: absolute;
                        border: 1px solid #0000001A;
                        border-radius: 5px;
                        padding-top: 10px;
                        background-color: #fff;
                        width: 100%;
                        max-height: 200px;
                        overflow-x: auto;

                        .select_once{
                            margin-bottom: 10px;
                            color: black;
                            font-weight: 400;
                            font-size: 17px;
                            line-height: 22px;
                            padding: 10px;

                            &:hover{
                                background-color: #F5F6FA;
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
            
            .button{
                color: #fff;
                font-weight: 700;
                font-size: 16px;
                line-height: 27px;
                padding: 10px 32px;
                border-radius: 10px;
                float: right;
            }
        }
    }
</style>