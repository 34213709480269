<template>
    <footer class="footer footer-nd" id="main_footer">
        <div class="footer_block-nd">
            <div class="container-l">
                <div class="row" style="padding-bottom: 30px;">
                    <div class="col-4 pdl-40">
                        <a class="logotype" href="#">
                            <img src="@/img/nd-imgs/footer_logo.png" alt="">
                        </a>
                    </div>
                </div>
    
                <div class="row" style="padding-bottom: 30px;">
                    <div class="col-4 mr-2 left_part_footer pdl-40">
                        <div class="footer_contacts">
                            <p class="contact_header">Контакты</p>

                            <div class="open_region_row">
                                <img @click="isShowRegionsList = !isShowRegionsList" class="point_icon" src="@/assets/img/navbar_point_icon.png" alt="">
                                <p @click="isShowRegionsList = !isShowRegionsList" class="navbar_city_name">{{ choosenCity }}</p>
                                <img @click="isShowRegionsList = !isShowRegionsList" v-if="!isShowRegionsList" class="arrow_icon" src="@/assets/img/arrow_down.png" alt="">
                                <img @click="isShowRegionsList = !isShowRegionsList" v-if="isShowRegionsList" class="arrow_icon" src="@/assets/img/arrow_up.png" alt="">

                                <div v-if="isShowRegionsList" class="regions_list_block">
                                    <p class="regions_header">Выберите город</p>
                                    <div class="lists">
                                        <ul class="regions_list">
                                            <li @click="choosenCountry = 'ru'" :class="choosenCountry == 'ru' ? '-active' : ''">
                                                Россия
                                                <img class="arrow_icon" v-if="choosenCountry == 'ru'" src="@/assets/img/red_arrow_right.png" alt="">
                                            </li>
                                            <li @click="choosenCountry = 'kz'" :class="choosenCountry == 'kz' ? '-active' : ''">
                                                Казахстан
                                                <img class="arrow_icon" v-if="choosenCountry == 'kz'" src="@/assets/img/red_arrow_right.png" alt="">
                                            </li>
                                        </ul>
                                        <ul class="cities_list">
                                            <li
                                                :class="choosenCity == cityRegion.name ? '-active' : ''"
                                                v-for="cityRegion, index in regionCityArray"
                                                :key="index"
                                                v-show="(choosenCountry == 'kz' && cityRegion.region == 'H2018') || (choosenCountry == 'ru' && cityRegion.region != 'H2018')"
                                                @click="setChoosenCity(cityRegion)"
                                            >
                                                {{ cityRegion.name }}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <a v-if="sourceUtmPhone" class="footer_phone_link" :href="'tel:' + maskedSourceUtmPhone">{{ maskedSourceUtmPhone }}</a>
                            <a v-if="!sourceUtmPhone && currentAddress" class="footer_phone_link" :href="'tel:' + currentAddressPhone">{{ currentAddressPhone }}</a>
                            <p class="contact_email_header">Почта</p>
                            <a v-if="sourceUtmEmail" :href="'mailto:' + sourceUtmEmail">{{ sourceUtmEmail }}</a>
                            <a v-if="!sourceUtmEmail && currentAddress" :href="'mailto:' + currentAddress.email">{{ currentAddress.email }}</a>
                        </div>
                    </div>
    
                    <div class="col-4 mr-2">
                        <div class="subcol">
                            <div class="label">Разделы каталога</div>
                            <ul class="list navigation-footer">
                                <li 
                                    class="list-item" 
                                    v-for="category in secondLevelCategory" 
                                    :key="category.id"
                                    @click="push('catalog/' + category.id_1c)"
                                >
                                    <a 
                                        class="list-link"
                                    >
                                        {{ category.name }}
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="label">Разделы сайта</div>
                        <ul class="list navigation-footer">
                            <li class="list-item">
                                <ul class="list sub">
                                    <li class="list-item" @click="push('aboutcompany')">
                                        <a class="list-link">О компании</a>
                                    </li>
                                    <li class="list-item" @click="pushToBuild('metall')">
                                        <a class="list-link">Металлоконструкции</a>
                                    </li>
                                    <li class="list-item" @click="push('objects')">
                                        <a class="list-link">Наши объекты</a>
                                    </li>
                                    <li class="list-item" @click="push('documents')">
                                        <a class="list-link">Документы</a>
                                    </li>
                                    <li class="list-item" @click="push('offers')">
                                        <a class="list-link">Акции и предложения</a>
                                    </li>
                                    <li class="list-item" @click="push('admin/index')" v-if="can('admin')">
                                        <a class="list-link">Администратор</a>
                                    </li>
                                    <li class="list-item" @click="push('contacts')">
                                        <a class="list-link">Контакты</a>
                                    </li>
                                    <li class="list-item" @click="pushToCalculators">
                                        <a class="list-link">Конструктор</a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-bottom footer-bottom-nd">
            <div class="container-l">
                <div class="row" style="padding-top: 43px; padding-bottom: 30px;">
                    <div class="col-4 mr-2 pdl-40">
                        <div class="label">ОГРН/ИНН: 6829063243 / 682901001</div>
                    </div>
                    <div class="col-4 mr-2">
                        <div class="label">Данный интернет-сайт носит исключительно информационный характер, и ни при каких условиях информация и цены, размещенные на сайте, не являются публичной офертой (ст. 437 ГК РФ).</div>
                    </div>
                    <div class="col-4">
                        <div class="label">
                            ООО «Роспромстрой» 
                            © {{ currentYear }} Компания «МаякМеталл»
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>
<script>
import Vuex from 'vuex'
import Functions from '@/utils/functions'
import Acl from '@/utils/acl'
// import regionSelect from '@/components/choices-select/region-select.vue'

export default {
    mixins: [Functions, Acl],
    components: {
        // regionSelect,  
    },
    data() {
        return {
            city: null,
            defaultValue: '',
            isSetDefaultValue: false,
            isReady: false,
            choosenCountry: 'ru',
            choosenCity: '',
            isCheckedLocalRegion: false,
            isShowRegionButton: true,
            isShowRegionsList: false,
        }
    },
    computed:{
        ...Vuex.mapGetters({
            regions: 'dictionary/regions',
            main: 'app/main',
            topLevelCategory: 'dictionary/topLevelCategory',
            region: 'auth/city',
            cityName: 'auth/cityName',
            regionAdresses: 'app/regionAdresses',
            secondLevelCategory: 'dictionary/secondLevelCategory',

            sourceUtmPhone: 'app/sourceUtmPhone',
            sourceUtmEmail: 'app/sourceUtmEmail',

            uiNetworks: 'ui_network/networks',
            offices: 'ui_contacts/offices',
        }),
        maskedSourceUtmPhone() {
            let masked = ''
            
            let phone = this.sourceUtmPhone
            masked += '+' + phone.substring(0, 1) 
            masked += ' (' + phone.substring(1, 4) + ') ' 
            masked += phone.substring(4, 7) 
            masked += '-' + phone.substring(7)  
            
            return masked
        },
        currentAddress() {
            let object = null
            this.offices.map((element) => {
                if (element.city == this.choosenCity) {
                    object = element
                }
            })
            return object
        },
        currentAddressPhone() {
            var phone = '8 (952) 647-00-63'
            var phones = []
            if (this.currentAddress.phone) {
                phones = this.currentAddress.phone.split(',')
                if (phones[0]) {
                    phone = phones[0]
                }
            }
            return phone
        },
        regionCityArray() {
            var array_result = []
            var array = this.offices
            array.map((element) => {
                if (!this.checkIssetElementInArray(array_result, element.city)) {
                    array_result.push({name: element.city, value: element.city, region: element.region})
                }
            })
            return array_result
        },
        currentYear() {
            var date = new Date()
            var year = date.getFullYear()
            return year
        },
    },
    watch: {
        isReady() {
            this.setDefaultCity()
        },
        topLevelCategory: {
            handler: function () {
                if (this.isSetDefaultValue == false) {
                    this.isReady = true
                }
            },
            deep: true
        },
        cityName() {
            this.checkCityLocal()
        },
    },
    mounted() {
        this.setDefaultCity()
    },
    created(){
        if(localStorage.fromMayak){
            this.from =  JSON.parse(localStorage.getItem("fromMayak"))
        }
        this.checkCityLocal()
        
    },
    methods: {
        ...Vuex.mapMutations({
            setCity:'auth/setCity',
            setRegionCode: 'auth/setRegionCode',
            setOrder: 'basket/setOrder',
            setCityName: 'auth/setCityName',
        }),
        checkIssetElementInArray(array, value) {
            var isset = false
            array.map((element) => {
                if (element.name == value) {
                    isset = true
                }
            })
            return isset
        },
        checkCityLocal() {
            var regionObject = JSON.parse(localStorage.getItem("mayakRegionObject"))
            if (regionObject) {
                this.city = regionObject.region
                this.choosenCity = regionObject.city
            }
        },
        pushToBuild(str) {
            if (str == 'build') {
                window.open('https://mk.mayakmetall.ru/', '_blank')
            }else if (str == 'metall') {
                window.open('https://mk.mayakmetall.ru/mk', '_blank')
            }
        },
        getFirstOfficeOfRegion(region) {
            var value = null
            var isSeted = false
            this.offices.map((element) => {
                if (element.region == region && isSeted == false) {
                    value = element.city
                    isSeted = true
                }
            })
            return value
        },
        changeCity(){
            this.setCity(this.city)
            this.choosenCity = this.getFirstOfficeOfRegion(this.city)
            localStorage.setItem('mayakRegionObject', JSON.stringify({city: this.getFirstOfficeOfRegion(this.city), region: this.city}))
            this.setRegionCode({region: this.city, array: this.topLevelCategory})
            if (localStorage.mayakOrderId) {
                localStorage.removeItem("mayakOrderId")
                this.setOrder(
                    {
                        id: null,
                        positions: [],
                        
                        account_type: null,
                        location: null,
                        postcode: null,

                        delivery_type: null,
                        pay_type: null,

                        organization_name: null,
                        organization_address: null,
                        inn: null,
                        kpp: null,

                        fio: null,
                        email:null,
                        phone: null,
                        comment: null,
                    }
                )
            }
        },
        setDefaultCity() {
            let name = ''

            if (!this.city) {
                name = 'Выберите регион'
            }else{
                this.topLevelCategory.map((element) => {
                    if (element.id_1c == this.city) {
                        name = element.name
                    }
                })
            }

            if (name) {
                this.defaultValue = name
                this.isSetDefaultValue = true
            }
        },
        pushToCalculators() {
            this.push('')

            setTimeout(() => {
                let element = document.getElementById('main_page_calcurators')
                let top = element.offsetTop
                window.scrollTo(0, top)
            }, 500)
        },
        setChoosenCity(element) {
            localStorage.setItem('mayakRegionObject', JSON.stringify({city: element.name, region: element.region, utm_phone: ''}))
            this.choosenCity = element.name
            this.setCityName(element.name)
            this.setCity(element.region)
            this.isShowRegionsList = false
            if (localStorage.mayakOrderId) {
                localStorage.removeItem("mayakOrderId")
                this.setOrder(
                    {
                        id: null,
                        positions: [],
                        
                        account_type: null,
                        location: null,
                        postcode: null,

                        delivery_type: null,
                        pay_type: null,

                        organization_name: null,
                        organization_address: null,
                        inn: null,
                        kpp: null,

                        fio: null,
                        email:null,
                        phone: null,
                        comment: null,
                    }
                )
            }
        },
    },
}
</script>